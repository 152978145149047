 .section-x-margins {
   padding-left: $container-margins;
   padding-right: $container-margins;

   // Extra Small devices (landscape phones, 376px and up)
   @include media-breakpoint-up(xs) {
     padding-left: $container-margins-xs;
     padding-right: $container-margins-xs;
   }

   // Small devices (landscape phones, 576px and up)
   @include media-breakpoint-up(sm) {
     padding-left: $container-margins-sm;
     padding-right: $container-margins-sm;
   }

   // Medium devices (tablets, 768px and up)
   @include media-breakpoint-up(md) {
     padding-left: $container-margins-md;
     padding-right: $container-margins-md;
   }

   // Large devices (desktops, 992px and up)
   @include media-breakpoint-up(lg) {
     padding-left: $container-margins-lg;
     padding-right: $container-margins-lg;
   }

   // X-Large devices (large desktops, 1200px and up)
   @include media-breakpoint-up(xl) {
     padding-left: $container-margins-xl;
     padding-right: $container-margins-xl;
   }
 }

 .section-left-margins {
   padding-left: $container-margins;

   // Extra Small devices (landscape phones, 376px and up)
   @include media-breakpoint-up(xs) {
     padding-left: $container-margins-xs;
   }

   // Small devices (landscape phones, 576px and up)
   @include media-breakpoint-up(sm) {
     padding-left: $container-margins-sm;
   }

   // Medium devices (tablets, 768px and up)
   @include media-breakpoint-up(md) {
     padding-left: $container-margins-md;
   }

   // Large devices (desktops, 992px and up)
   @include media-breakpoint-up(lg) {
     padding-left: $container-margins-lg;
   }

   // X-Large devices (large desktops, 1200px and up)
   @include media-breakpoint-up(xl) {
     padding-left: $container-margins-xl;
   }

   &-md {
     padding-left: $container-margins;

     // Extra Small devices (landscape phones, 376px and up)
     @include media-breakpoint-up(xs) {
       padding-left: $container-margins-xs;
     }

     // Small devices (landscape phones, 576px and up)
     @include media-breakpoint-up(sm) {
       padding-left: $container-margins-sm;
     }

     // Medium devices (tablets, 768px and up)
     @include media-breakpoint-up(md) {
       padding-left: $container-margins-md;
     }

     // Large devices (desktops, 992px and up)
     @include media-breakpoint-up(lg) {
       padding-left: 0px;
     }

     // X-Large devices (large desktops, 1200px and up)
     @include media-breakpoint-up(xl) {
       padding-left: 0px;
     }
   }
 }

 .section-right-margins {
   padding-right: $container-margins;

   // Extra Small devices (landscape phones, 376px and up)
   @include media-breakpoint-up(xs) {
     padding-right: $container-margins-xs;
   }

   // Small devices (landscape phones, 576px and up)
   @include media-breakpoint-up(sm) {
     padding-right: $container-margins-sm;
   }

   // Medium devices (tablets, 768px and up)
   @include media-breakpoint-up(md) {
     padding-right: $container-margins-md;
   }

   // Large devices (desktops, 992px and up)
   @include media-breakpoint-up(lg) {
     padding-right: $container-margins-lg;
   }

   // X-Large devices (large desktops, 1200px and up)
   @include media-breakpoint-up(xl) {
     padding-right: $container-margins-xl;
   }
 }

 $sec-padding: 30px;
 $sec-padding-xs: 50px;
 $sec-padding-sm: 60px;
 $sec-padding-md: 70px;
 $sec-padding-lg: 80px;
 $sec-padding-xl: 100px;


 .pt-normal {
   padding-top: $sec-padding/2;

   // Extra Small devices (landscape phones, 376px and up)
   @include media-breakpoint-up(xs) {
     padding-top: $sec-padding-xs/2;
   }

   // Small devices (landscape phones, 576px and up)
   @include media-breakpoint-up(sm) {
     padding-top: $sec-padding-sm/2;
   }

   // Medium devices (tablets, 768px and up)
   @include media-breakpoint-up(md) {
     padding-top: $sec-padding-md/2;
   }

   // Large devices (desktops, 992px and up)
   @include media-breakpoint-up(lg) {
     padding-top: $sec-padding-lg/2;
   }

   // X-Large devices (large desktops, 1200px and up)
   @include media-breakpoint-up(xl) {
     padding-top: $sec-padding-xl/2;
   }
 }

 .pb-normal {
   padding-bottom: $sec-padding/2;

   // Extra Small devices (landscape phones, 376px and up)
   @include media-breakpoint-up(xs) {
     padding-bottom: $sec-padding-xs/2;
   }

   // Small devices (landscape phones, 576px and up)
   @include media-breakpoint-up(sm) {
     padding-bottom: $sec-padding-sm/2;
   }

   // Medium devices (tablets, 768px and up)
   @include media-breakpoint-up(md) {
     padding-bottom: $sec-padding-md/2;
   }

   // Large devices (desktops, 992px and up)
   @include media-breakpoint-up(lg) {
     padding-bottom: $sec-padding-lg/2;
   }

   // X-Large devices (large desktops, 1200px and up)
   @include media-breakpoint-up(xl) {
     padding-bottom: $sec-padding-xl/2;
   }
 }

 .pt-lg {
   padding-top: $sec-padding;

   // Extra Small devices (landscape phones, 376px and up)
   @include media-breakpoint-up(xs) {
     padding-top: $sec-padding-xs;
   }

   // Small devices (landscape phones, 576px and up)
   @include media-breakpoint-up(sm) {
     padding-top: $sec-padding-sm;
   }

   // Medium devices (tablets, 768px and up)
   @include media-breakpoint-up(md) {
     padding-top: $sec-padding-md;
   }

   // Large devices (desktops, 992px and up)
   @include media-breakpoint-up(lg) {
     padding-top: $sec-padding-lg;
   }

   // X-Large devices (large desktops, 1200px and up)
   @include media-breakpoint-up(xl) {
     padding-top: $sec-padding-xl;
   }
 }

 .pb-lg {
   padding-bottom: $sec-padding;

   // Extra Small devices (landscape phones, 376px and up)
   @include media-breakpoint-up(xs) {
     padding-bottom: $sec-padding-xs;
   }

   // Small devices (landscape phones, 576px and up)
   @include media-breakpoint-up(sm) {
     padding-bottom: $sec-padding-sm;
   }

   // Medium devices (tablets, 768px and up)
   @include media-breakpoint-up(md) {
     padding-bottom: $sec-padding-md;
   }

   // Large devices (desktops, 992px and up)
   @include media-breakpoint-up(lg) {
     padding-bottom: $sec-padding-lg;
   }

   // X-Large devices (large desktops, 1200px and up)
   @include media-breakpoint-up(xl) {
     padding-bottom: $sec-padding-xl;
   }
 }