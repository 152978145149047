.alert {
  width: 100%;
  text-align: center;
  padding: 18px 18px;
  margin: 10px auto;
  background-color: #0E0019;
  border: 2px solid #9100FF;
  border-radius: 15px;
  color: var(--text-color);
  font-size: 18px;

  &-danger {}

  &-info {}
}