/**
 * = Modals
 */
.modal-backdrop {
  --bs-backdrop-zindex: 888;
}

.modal {
  --bs-modal-zindex: 900;
  --bs-modal-padding: 1rem;
  --bs-modal-width: 500px;
  --bs-modal-margin: 0.5rem;
  // --bs-modal-color: #{$primary-active};
  --bs-modal-bg: #{$primary-active};
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
}

// .modal {
//   .modal-dialog {
//     .modal-content {

//       .modal-header,
//       .modal-footer {
//         border: none;
//       }

//       .modal-title {}

//       .modal-content {

//         .heading {}
//       }

//       .close {
//         &>span:not(.sr-only) {}
//       }
//     }
//   }
// }

// @each $color,
// $value in $theme-colors {
//   .modal-#{$color} {
//     @include modal-variant($value);
//   }
// }