 .container {
   padding-right: 16px;
   padding-left: 16px;

   // Extra Small devices (landscape phones, 376px and up)
   @include media-breakpoint-up(xs) {
     padding-right: 16px;
     padding-left: 16px;
   }

   // Small devices (landscape phones, 576px and up)
   @include media-breakpoint-up(sm) {
     padding-right: 16px;
     padding-left: 16px;
   }

   // Medium devices (tablets, 768px and up)
   @include media-breakpoint-up(md) {
     padding-right: 12px;
     padding-left: 12px;
   }

   // Large devices (desktops, 992px and up)
   @include media-breakpoint-up(lg) {
     padding-right: 12px;
     padding-left: 12px;
   }

   // X-Large devices (large desktops, 1200px and up)
   @include media-breakpoint-up(xl) {}
 }

 .h-100vh {
   height: 100vh !important;
 }

 .mh-100vh {
   min-height: 100vh !important;
 }