 @import "./../../Styles/variables";
 @import "./../../Styles/theme/mixins";

 $features-border-radius: 25px;

 .features {
   @include dark(background-color, #351D49, #e8e4ed);
   @include dark(box-shadow, 0px 4px 50px 10px rgba(0, 0, 0, 0.5), 0px 4px 35px 10px rgba(0, 0, 0, 0.25));
   border-radius: $features-border-radius;
   padding: 17px;

   &__body {
     background-color: #3A2050;
     @include dark(background-color, #3A2050, rgb(209, 182, 231));
     @include dark(box-shadow, 0px 4px 50px rgba(0, 0, 0, 0.5), 0px 4px 30px rgba(0, 0, 0, 0.35));
     border-radius: $features-border-radius;
     padding: 12px;
   }

   &__icon {
     width: 77px;
     height: 77px;
     border-radius: 50%;
     background-color: var(--dark-2);
     display: flex;
     justify-content: center;
     align-items: center;
     margin-bottom: 16px;
   }

   &__text {
     font-style: normal;
     font-weight: 500;
     font-size: 14px;
     line-height: 28px;
     text-align: center;
     color: var(--text-color);
   }

   &:hover &,
   &:focus &,
   &:active & {
     transition: all 2s ease-in-out;

     &__body {}

     &__icon {
       box-shadow: 0px 4px 20px 5px rgba($primary-active, 0.5);
       transition: all 0.35s ease-in-out;
     }
   }

   &:hover,
   &:focus,
   &:active {

     svg,
     path {
       animation: iconAnimation 3s ease alternate infinite;
     }
   }
 }

 .features-tilt {
   border-radius: $features-border-radius  !important;
 }

 @keyframes iconAnimation {
   0% {
     stroke: $primary;
     stroke-width: .05em;
     stroke-dasharray: 155 155;
     stroke-dashoffset: 155;
   }

   50% {
     stroke: var(--text-color);
     stroke-width: .05em;
     stroke-dasharray: 155 155;
     stroke-dashoffset: 355;
   }

   100% {
     stroke: $primary;
     stroke-width: .05em;
     stroke-dasharray: 155 155;
     stroke-dashoffset: 155;
   }
 }