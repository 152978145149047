 @import "./../../../../Styles/variables";

 .hero-home {
   $fs-sh: 0.3em;
   $fs-sh-xs: 0.3em;
   $fs-sh-sm: 0.35em;
   $fs-sh-md: 0.45em;
   $fs-sh-lg: 0.65em;
   $fs-sh-xl: 0.75em;
   $fs-sh-xxl: 0.75em;

   &__sub-heading {
     font-family: var(--font-family-sec);
     font-style: normal;
     font-weight: 600;
     color: var(--text-color);
     letter-spacing: 0.3em;
     font-size: 13px;
     line-height: 16px;
     margin-bottom: 10px;

     @include media-breakpoint-up(xs) {
       letter-spacing: 0.35em;
       font-size: 14px;
       line-height: 17px;
       margin-bottom: 11px;
     }

     @include media-breakpoint-up(sm) {
       letter-spacing: 0.35em;
       font-size: 14px;
       line-height: 17px;
       margin-bottom: 11px;
     }

     @include media-breakpoint-up(md) {
       letter-spacing: 0.45em;
       font-size: 15px;
       line-height: 18px;
       margin-bottom: 13px;
     }

     @include media-breakpoint-up(lg) {
       letter-spacing: 0.65em;
       font-size: 15px;
       line-height: 19px;
       margin-bottom: 15px;
     }

     @include media-breakpoint-up(xl) {
       letter-spacing: 0.75em;
       font-size: 16px;
       line-height: 19px;
       margin-bottom: 17px;
     }

     @include media-breakpoint-up(xxl) {}
   }

   &__heading {
     position: relative;
     font-style: normal;
     color: var(--text-color);
     display: inline-block;
     font-weight: 600;
     font-size: 35px;
     line-height: 50px;
     margin-bottom: 20px;

     &::after {
       content: "";
       display: inline-block;
       width: 208px;
       width: 25%;
       height: 15px;
       background: #C301AF;
       position: absolute;
       right: 0;
       bottom: 15px;
       z-index: -1;
     }

     @include media-breakpoint-up(sm) {
       font-size: 50px;
       line-height: 60px;
       margin-bottom: 22px;
     }

     @include media-breakpoint-up(md) {
       font-size: 62px;
       line-height: 80px;
       margin-bottom: 25px;
     }

     @include media-breakpoint-up(lg) {
       font-size: 76px;
       line-height: 100px;
       margin-bottom: 35px;
     }

     @include media-breakpoint-up(xl) {
       font-size: 86px;
       line-height: 108px;
       margin-bottom: 40px;
     }

     @include media-breakpoint-up(xxl) {
       font-size: 96px;
       line-height: 118px;
       margin-bottom: 45px;
     }
   }

   &__description {
     font-family: var(--font-family-sec);
     color: var(--text-color);
     margin-right: auto;
     margin-left: auto;
     border-left: 8px solid #A733FF;
     font-style: normal;
     font-weight: 500;
     padding: 0 5px;
     font-size: 16px;
     line-height: 23px;
     letter-spacing: 0.02em;
     width: 100%;

     @include media-breakpoint-up(sm) {
       padding: 0 10px;
       font-size: 17px;
       line-height: 23px;
       letter-spacing: 0.02em;
       width: 95%;
     }

     @include media-breakpoint-up(md) {
       padding: 0 12px;
       font-size: 18px;
       line-height: 24px;
       letter-spacing: 0.02em;
       width: 90%;
     }

     @include media-breakpoint-up(lg) {
       padding: 0 15px;
       font-size: 19px;
       line-height: 24px;
       letter-spacing: 0.02em;
       width: 90%;
     }

     @include media-breakpoint-up(xl) {
       padding: 0 20px;
       font-size: 20px;
       line-height: 24px;
       letter-spacing: 0.02em;
       width: 85%;
     }

     @include media-breakpoint-up(xxl) {
       padding: 0 22px;
       font-size: 20px;
       line-height: 24px;
       letter-spacing: 0.02em;
       width: 75%;
     }
   }
 }

 .bg-inner-globe {
   background: radial-gradient(12% 28% at 5% 65%, #c301b03f 20%, transparent 100%),
     radial-gradient(30% 30% at 85% 5%, #9000ff38 20%, transparent 100%),
     linear-gradient(152deg, transparent 1%, transparent 100%);
   //  @include dark(background);
   background-size: 100% 100vh;
   z-index: 5;
   height: 100vh;
   position: relative;
 }