 @import "./../../Styles/variables";

 .notFound {
   min-height: 100vh;
   display: grid;
   justify-content: center;
   align-items: center;

   &__icon {
     color: var(--text-color);
     display: block;
     font-weight: 600;
     font-size: 26px;
     line-height: 36px;
     margin-bottom: 20px;

     @include media-breakpoint-up(sm) {
       font-size: 28px;
       line-height: 40px;
       margin-bottom: 20px;
     }

     @include media-breakpoint-up(md) {
       font-size: 32px;
       line-height: 40px;
       margin-bottom: 35px;
     }

     @include media-breakpoint-up(lg) {
       font-size: 36px;
       line-height: 46px;
       margin-bottom: 40px;
     }

     @include media-breakpoint-up(xl) {
       font-size: 40px;
       line-height: 50px;
       margin-bottom: 50px;
     }

     @include media-breakpoint-up(xxl) {
       font-size: 44px;
       line-height: 54px;
       margin-bottom: 55px;
     }
   }

   &__heading {
     font-style: normal;
     color: var(--text-color);
     display: inline-block;
     font-weight: 600;
     font-size: 28px;
     line-height: 40px;
     margin-bottom: 15px;

     @include media-breakpoint-up(sm) {
       font-size: 30px;
       line-height: 42px;
       margin-bottom: 15px;
     }

     @include media-breakpoint-up(md) {
       font-size: 34px;
       line-height: 44px;
       margin-bottom: 15px;
     }

     @include media-breakpoint-up(lg) {
       font-size: 36px;
       line-height: 46px;
       margin-bottom: 15px;
     }

     @include media-breakpoint-up(xl) {
       font-size: 40px;
       line-height: 50px;
     }

     @include media-breakpoint-up(xxl) {
       font-size: 44px;
       line-height: 54px;
     }
   }

   &__description {
     font-family: var(--font-family-sec);
     color: var(--text-color);
     margin-right: auto;
     margin-left: auto;
     border-left: 8px solid #A733FF;
     font-style: normal;
     font-weight: 500;
     padding: 0 5px;
     font-size: 16px;
     line-height: 36px;

     @include media-breakpoint-up(sm) {
       padding: 0 10px;
       font-size: 16px;
       line-height: 36px;
     }

     @include media-breakpoint-up(md) {
       padding: 0 10px;
       font-size: 16px;
       line-height: 36px;
     }

     @include media-breakpoint-up(lg) {
       padding: 0 12px;
       font-size: 16px;
       line-height: 36px;
     }

     @include media-breakpoint-up(xl) {
       padding: 0 12px;
       font-size: 16px;
       line-height: 36px;
     }

     @include media-breakpoint-up(xxl) {
       font-size: 16px;
       line-height: 36px;
     }
   }
 }