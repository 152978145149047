.form-select {
  padding-right: 50px !important;
  min-width: 65px;

  .form-select-lg {}
}


//Select
.custom-select {
  box-shadow: none;

  &.custom-select-shadow {
    transition: box-shadow .15s ease;

    &:focus {}
  }

  &:hover {
    cursor: pointer;
  }
}