 @import "./../../../../Styles/variables";
 $product-border-radius: 25px;

 .product {
   $root: &;

   &__avatar {
     filter: drop-shadow(0px 4px 70px #000000);
     filter: drop-shadow(2px 4px 10px black);
   }

   &__body {

     #{$root}:hover &,
     #{$root}:focus &,
     #{$root}:active & {}
   }

   &__heading {
     color: var(--text-color);
     font-weight: 600;
     font-size: 26px;
     line-height: 42px;

     // Extra Small devices (landscape phones, 376px and up)
     @include media-breakpoint-up(xs) {
       font-size: 30px;
       line-height: 42px;
     }

     // Small devices (landscape phones, 576px and up)
     @include media-breakpoint-up(sm) {
       font-size: 34px;
       line-height: 44px;
     }

     // Medium devices (tablets, 768px and up)
     @include media-breakpoint-up(md) {
       font-size: 38px;
       line-height: 48px;
     }

     // Large devices (desktops, 992px and up)
     @include media-breakpoint-up(lg) {
       font-size: 40px;
       line-height: 50px;
     }

     // X-Large devices (large desktops, 1200px and up)
     @include media-breakpoint-up(xl) {
       font-size: 44px;
       line-height: 54px;
     }
   }

   &__description {
     font-family: var(--font-family-sec);
     color: var(--text-color);
     position: relative;
     border-left: 5px solid #9100FF;
     font-weight: 500;
     font-size: 13px;
     line-height: 26px;
     padding-left: 12px;

     // Extra Small devices (landscape phones, 376px and up)
     @include media-breakpoint-up(xs) {
       font-size: 14px;
       line-height: 24px;
       padding-left: 12px;
     }

     // Small devices (landscape phones, 576px and up)
     @include media-breakpoint-up(sm) {
       font-size: 14px;
       line-height: 26px;
       padding-left: 14px;
     }

     // Medium devices (tablets, 768px and up)
     @include media-breakpoint-up(md) {
       font-size: 15px;
       line-height: 28px;
       padding-left: 16px;
     }

     // Large devices (desktops, 992px and up)
     @include media-breakpoint-up(lg) {
       font-size: 16px;
       line-height: 30px;
       padding-left: 18px;
     }

     // X-Large devices (large desktops, 1200px and up)
     @include media-breakpoint-up(xl) {
       font-size: 16px;
       line-height: 32px;
       padding-left: 20px;
     }
   }

   &__priceWrapper {
     display: flex;
     align-items: center;
   }

   &__priceEth {
     padding: 8px 16px;
     border: 1px solid #FE01E4;
     border-radius: 5px;
     font-family: var(--font-family-sec);
     font-weight: 600;
     font-size: 16px;
     line-height: 19px;
     color: var(--text-color);
     margin-right: 10px;
   }

   &__priceDollar {
     padding: 8px 16px;
     border: 1px solid var(--text-color);
     border-radius: 5px;
     font-family: var(--font-family-sec);
     font-weight: 600;
     font-size: 16px;
     line-height: 19px;
     color: var(--text-color);
     margin-right: 10px;
   }

   &__stock {
     font-family: var(--font-family-sec);
     font-weight: 500;
     font-size: 18px;
     line-height: 22px;
     color: #B3B3B3;
   }
 }


 .products-splide {

   .splide__pagination {
     bottom: -30px;

     li {

       .splide__pagination__page {
         width: 12px;
         height: 12px;
         border-radius: 50%;
         background: #000000;

         &.is-active {
           background: linear-gradient(180deg, #C301AF 0%, #9100FF 100%);
         }
       }
     }
   }

   .splide__track--nav>.splide__list>.splide__slide {
     border-width: 0px !important;
     opacity: 1;

     &.is-active {}
   }
 }

 .arrows {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   margin: 0 auto;
 }

 .arrow {
   border-radius: 50%;
   border: 2px solid #9100FF;
   transform: translateY(0%) !important;
   position: static !important;
   margin: 0 5px;
   width: 25px;
   height: 25px;
   padding: 5px;

   // Extra Small devices (landscape phones, 376px and up)
   @include media-breakpoint-up(xs) {
     margin: 0 6px;
     width: 30px;
     height: 30px;
     padding: 6px;
   }

   // Small devices (landscape phones, 576px and up)
   @include media-breakpoint-up(sm) {
     margin: 0 8px;
     width: 40px;
     height: 40px;
     padding: 8px;
   }

   // Medium devices (tablets, 768px and up)
   @include media-breakpoint-up(md) {
     margin: 0 10px;
     width: 50px;
     height: 50px;
     padding: 10px;
   }

   // Large devices (desktops, 992px and up)
   @include media-breakpoint-up(lg) {
     margin: 0 12px;
     width: 60px;
     height: 60px;
     padding: 12px;
   }

   // X-Large devices (large desktops, 1200px and up)
   @include media-breakpoint-up(xl) {
     margin: 0 15px;
     width: 70px;
     height: 70px;
     padding: 16px;
   }

   &--next {}

   &--prev {}

   &:hover,
   &:focus,
   &:active {
     background: #9100FF;
   }
 }