 @import "./../../../../Styles/variables";
 $teams-border-radius: 25px;

 .social {
   &__sub-heading {
     font-family: var(--font-family-sec);
     font-style: normal;
     font-weight: 600;
     color: #FE01E4;
     letter-spacing: 0.45em;
     font-size: 13px;
     line-height: 16px;
     margin-bottom: 10px;

     @include media-breakpoint-up(sm) {
       letter-spacing: 0.50em;
       font-size: 14px;
       line-height: 17px;
       margin-bottom: 11px;
     }

     @include media-breakpoint-up(md) {
       letter-spacing: 0.60em;
       font-size: 15px;
       line-height: 18px;
       margin-bottom: 13px;
     }

     @include media-breakpoint-up(lg) {
       letter-spacing: 0.70em;
       font-size: 15px;
       line-height: 19px;
       margin-bottom: 15px;
     }

     @include media-breakpoint-up(xl) {
       letter-spacing: 0.75em;
       font-size: 16px;
       line-height: 19px;
       margin-bottom: 17px;
     }

     @include media-breakpoint-up(xxl) {}
   }

   &__heading {
     position: relative;
     font-style: normal;
     color: var(--text-color);
     display: inline-block;
     font-weight: 600;
     font-size: 26px;
     line-height: 36px;
     margin-bottom: 14px;

     @include media-breakpoint-up(sm) {
       font-size: 28px;
       line-height: 40px;
       margin-bottom: 16px;
     }

     @include media-breakpoint-up(md) {
       font-size: 32px;
       line-height: 40px;
       margin-bottom: 18px;
     }

     @include media-breakpoint-up(lg) {
       font-size: 36px;
       line-height: 46px;
       margin-bottom: 18px;
     }

     @include media-breakpoint-up(xl) {
       font-size: 40px;
       line-height: 50px;
       margin-bottom: 20px;
     }

     @include media-breakpoint-up(xxl) {
       font-size: 44px;
       line-height: 54px;
       margin-bottom: 20px;
     }
   }

   &__description {
     font-family: var(--font-family-sec);
     color: var(--text-color);
     margin-right: auto;
     margin-left: auto;
     border-left: 8px solid #A733FF;
     font-style: normal;
     font-weight: 500;
     padding: 0 5px;
     font-size: 16px;
     line-height: 23px;
     letter-spacing: 0.02em;
     width: 100%;

     @include media-breakpoint-up(sm) {
       padding: 0 10px;
       font-size: 17px;
       line-height: 23px;
       letter-spacing: 0.02em;
       width: 95%;
     }

     @include media-breakpoint-up(md) {
       padding: 0 12px;
       font-size: 18px;
       line-height: 24px;
       letter-spacing: 0.02em;
       width: 90%;
     }

     @include media-breakpoint-up(lg) {
       padding: 0 15px;
       font-size: 19px;
       line-height: 24px;
       letter-spacing: 0.02em;
       width: 90%;
     }

     @include media-breakpoint-up(xl) {
       padding: 0 20px;
       font-size: 20px;
       line-height: 24px;
       letter-spacing: 0.02em;
       width: 85%;
     }

     @include media-breakpoint-up(xxl) {
       padding: 0 22px;
       font-size: 20px;
       line-height: 24px;
       letter-spacing: 0.02em;
       width: 75%;
     }
   }
 }

 // SOCIAL LINKS
 .links {
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 14px;
   // width: 82px;
   // height: 76px;

   &__inner {
     background: var(--dark-2);
     z-index: -1;
     border-radius: 5px;
     padding: 7px;

     @include media-breakpoint-up(sm) {
       border-radius: 8px;
       padding: 8px;
     }

     @include media-breakpoint-up(md) {
       border-radius: 10px;
       padding: 10px;
     }

     @include media-breakpoint-up(lg) {
       border-radius: 12px;
       padding: 12px;
     }

     @include media-breakpoint-up(xl) {
       border-radius: 15px;
       padding: 15px;
     }

     @include media-breakpoint-up(xxl) {}
   }

   &__item {
     border-radius: 15px;
     padding: 1px;
     background: var(--dark-2);

     svg {
       width: 15px;
       height: 15px;

       @include media-breakpoint-up(sm) {
         width: 20px;
         height: 20px;
       }

       @include media-breakpoint-up(md) {
         width: 30px;
         height: 30px;
       }

       @include media-breakpoint-up(lg) {
         width: 35px;
         height: 35px;
       }

       @include media-breakpoint-up(xl) {
         width: 40px;
         height: 40px;
       }

       @include media-breakpoint-up(xxl) {}
     }

     svg,
     path {
       stroke: rgb(214, 0, 0);
       stroke-width: .05em;
       stroke-opacity: 1;
       stroke-linecap: butt;
       stroke-linejoin: bevel;
       stroke-dasharray: 155 155;
       stroke-dashoffset: 155;
     }

     &:hover,
     &:focus,
     &:active {
       background: linear-gradient(180deg, #9100FF 0%, #C301AF 100%);
       animation: animatedgradient 3s ease alternate infinite;
       background-size: 300% 300%;
       transition: all 2s ease-in-out;

       svg,
       path {
         animation: svgAnimation 3s ease alternate infinite;
       }
     }
   }

   &__icon {}
 }

 @keyframes svgAnimation {
   0% {
     stroke: $primary;
     stroke-width: .05em;
     stroke-opacity: 1;
     stroke-linecap: butt;
     stroke-linejoin: bevel;
     stroke-dasharray: 155 155;
     stroke-dashoffset: 155;
   }

   50% {
     stroke: var(--text-color);
     stroke-width: .1em;
     stroke-opacity: 1;
     stroke-linecap: butt;
     stroke-linejoin: bevel;
     stroke-dasharray: 155 155;
     stroke-dashoffset: 355;
   }

   100% {
     stroke: $primary;
     stroke-width: .05em;
     stroke-opacity: 1;
     stroke-linecap: butt;
     stroke-linejoin: bevel;
     stroke-dasharray: 155 155;
     stroke-dashoffset: 155;
   }
 }


 @keyframes animatedgradient {
   0% {
     background-position: 0% 50%;
   }

   100% {
     background-position: 0% 50%;
   }
 }


 // STATs
 .stat-wrapper {
   display: flex;
   justify-content: center;
 }

 .stat {
   text-align: center;

   &__number {
     color: var(--text-color);
     font-weight: 600;
     font-size: 22px;
     line-height: 34px;
     margin-bottom: 5px;

     @include media-breakpoint-up(sm) {
       font-size: 24px;
       line-height: 36px;
     }

     @include media-breakpoint-up(md) {
       font-size: 28px;
       line-height: 42px;
     }

     @include media-breakpoint-up(lg) {
       font-size: 34px;
       line-height: 45px;
     }

     @include media-breakpoint-up(xl) {
       font-size: 37px;
       line-height: 46px;
     }

     @include media-breakpoint-up(xxl) {
       font-size: 40px;
       line-height: 49px;
     }
   }

   &__title {
     color: var(--text-color);
     font-weight: 500;
     font-size: 14px;
     line-height: 22px;

     @include media-breakpoint-up(sm) {
       font-size: 15px;
       line-height: 24px;
     }

     @include media-breakpoint-up(md) {
       font-size: 16px;
       line-height: 26px;
     }

     @include media-breakpoint-up(lg) {
       font-size: 16px;
       line-height: 28px;
     }

     @include media-breakpoint-up(xl) {
       font-size: 16px;
       line-height: 30px;
     }

     @include media-breakpoint-up(xxl) {
       font-size: 16px;
       line-height: 36px;
     }
   }

   &__highlighted {
     color: #FE01E4;
   }
 }