 @import "./../../../../Styles/variables";

 .nft {
   background: var(--dark-3);
   border: 4px solid #42245B;
   border-radius: 15px;
   padding: 0px;
   margin-bottom: 20px;
   overflow: hidden;
   transition: border 0.35s ease-in-out;

   &:hover {
     border: 4px solid $primary;
   }

   &__media {
     object-fit: cover;
     padding: 0px;
     min-height: 105px !important;
    //  height: 95px !important;
   }

   &__body {
     padding: 15px;
     display: inline-block;
     text-decoration: none;
   }

   &__icon {
     height: 21px;
     width: 21px;
     object-fit: contain;
     margin-right: 6px;
   }

   &__name {
     font-weight: 600;
     font-size: 16px;
     line-height: 20px;
     color: var(--text-color);
     margin-bottom: 18px;
   }

   &__address {
     display: flex;
     align-items: center;
     font-weight: 500;
     font-size: 15px;
     line-height: 24px;
     letter-spacing: 0.01em;
     font-family: var(--font-family-sec);
     color: var(--text-color);
     margin-bottom: 8px;
   }

   &__map_level {
     display: flex;
     align-items: center;
     font-weight: 500;
     font-size: 15px;
     line-height: 24px;
     letter-spacing: 0.01em;
     font-family: var(--font-family-sec);
     color: var(--text-color);
     margin-bottom: 10px;
   }

   &__price {
     display: flex;
     align-items: center;
     font-weight: 600;
     font-size: 16px;
     line-height: 24px;
     letter-spacing: 0.01em;
     color: var(--text-color);
     margin-bottom: 10px;
   }

   &__owned-by {
     font-weight: 500;
     font-size: 14px;
     font-size: 16px;
     line-height: 24px;
     letter-spacing: 0.01em;
     font-family: var(--font-family-sec);
     color: var(--text-color);
     margin-bottom: 0px;

     b {
       font-weight: 600;
       font-family: var(--bs-body-font-family);
       color: var(--text-color);
     }
   }
 }

 .arrows {}

 .arrow {
   background: #fff;
   border-radius: 50%;
   padding: 5px;
   width: 24px;
   height: 24px;
 }