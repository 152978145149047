@import "./../../Styles/theme/mixins";

:root {}

.section {
  // min-height: 100vh;
}

.bg {
  $bg-gradient: radial-gradient(28% 28% at 5% 85%, #c301b054 20%, transparent 100%),
  radial-gradient(30% 30% at 85% 5%, #9000ff4b 20%, #073AFF00 100%),
  linear-gradient(152deg, #281637 1%, #281637 100%);
  $bg-gradient-light: radial-gradient(28% 28% at 5% 85%, #c301b054 20%, #073AFF00 100%),
  radial-gradient(30% 30% at 85% 5%, #9000ff4b 20%, transparent 100%),
  linear-gradient(152deg, transparent 1%, transparent 100%);
  background-size: 100% 105vh;
  // @include dark(background-image, $bg-gradient, $bg-gradient-light);
  background-image: var(--bg-gradient-1);
}

.bg2 {
  $bg-gradient: linear-gradient(154deg, #980189 3.29%, #7400CC 95.81%);
  $bg-gradient-light: linear-gradient(154deg, #98018981 3.29%, #ffffff 95.81%);
  background-image: var(--bg-gradient-2);
}

.card {
  padding: 50px 15%;

  svg {
    margin-bottom: 32px;
  }

  &_heading {
    font-family: var(--font-family-primary);
    font-weight: 600;
    font-size: 44px;
    line-height: 54px;
    color: var(--text-color);
    margin-bottom: 2px;
  }

  &_text {
    font-family: var(--font-family-sec);
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: var(--white-3);
    margin-bottom: 32px;
  }
}

.img {
  &_avatars {
    height: 40px;
    margin-right: 16px;
  }

  &_text {
    font-family: var(--font-family-sec);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--white-3);
  }
}

// FORM 
.control {
  display: block;
  margin-bottom: 13px;

  &Label {
    font-family: var(--font-family-sec);
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-color);
    margin-bottom: 8px;
  }

  &Input {
    display: block;
    width: 100%;
    padding: 10px 14px;
    height: 44px;
    outline: none;
    border: 2px solid #43245B;
    @include dark(border-color, #43245B, #e7e7e7);
    background: #43245B;
    @include dark(background, #43245B, #e7e7e7);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-family: var(--font-family-sec);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #999999;
    @include dark(color, #999999, #43245B);
    margin-bottom: 5px;
    transition: 0.35s ease-in-out;

    &:hover {
      border: 2px solid var(--secondary);
    }

    &:focus,
    &:active {
      border: 2px solid var(--primary);
    }
  }

  &Msg {
    color: #ff7a7a;
    @include dark(color, #ff7a7a, #ff1414);
    line-height: 1;
    font-style: italic;
    font-size: 12px;
    margin-bottom: 0px;
  }
}