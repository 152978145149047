/**
 * = Navigation bars
 */
@import "./../../../Styles/variables";

.nav-has-bg {

  .navbar {
    background: var(--bs-body-bg);
  }
}

.navbar {
  padding: 20px 0 !important;
  z-index: 1000;
  z-index: 1030;

  .navbar-toggler-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    // Extra Small devices (landscape phones, 376px and up)
    @include media-breakpoint-up(xs) {
      width: 100%;
    }

    // Small devices (landscape phones, 576px and up)
    @include media-breakpoint-up(sm) {}

    // Medium devices (tablets, 768px and up)
    @include media-breakpoint-up(md) {
      width: 100%;
    }

    // Large devices (desktops, 992px and up)
    @include media-breakpoint-up(lg) {
      width: auto;
    }

    // X-Large devices (large desktops, 1200px and up)
    @include media-breakpoint-up(xl) {}

    // Complete styles for main navbar toggler and hamburger menu
    .navbar-toggler {
      position: relative;
      left: 5px;
      background-color: transparent;

      &.collapsed {
        background-color: $primary;

        svg {
          fill: #fff !important;
        }
      }

      &:not(.collapsed) {
        background-color: transparent;

        svg {
          fill: $primary  !important;
        }
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .navbar-brand {
    display: inline-block;
    text-transform: uppercase;

    img {
      min-width: 110px;
      height: 25px;

      // Extra Small devices (landscape phones, 376px and up)
      @include media-breakpoint-up(xs) {
        min-width: 110px;
        height: 30px;
      }

      // Small devices (landscape phones, 576px and up)
      @include media-breakpoint-up(sm) {
        min-width: 120px;
        height: 40px;
      }

      // Medium devices (tablets, 768px and up)
      @include media-breakpoint-up(md) {
        min-width: 150px;
        height: 40px;
      }

      // Large devices (desktops, 992px and up)
      @include media-breakpoint-up(lg) {
        height: 45px;
      }

      // X-Large devices (large desktops, 1200px and up)
      @include media-breakpoint-up(xl) {
        height: 50px;
      }
    }

    &:hover {
      img {
        animation: bounceIn 0.5s;
        transition: all 0.4s cubic-bezier(.8, 1.8, .75, .75);
      }
    }
  }

  // sticky 
  &.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 888;
    backdrop-filter: blur(6px);

    .navbar-brand {
      // img {
      //   filter: unset;
      // }
    }

    .navbar-toggler {
      border-color: #000;
      color: #000;
    }

    .nav-item {
      .nav-link {
        color: #131313;

        &.active,
        &:hover,
        &:focus,
        &:active {
          color: $primary;
        }
      }
    }
  }

  .navbar-nav {

    .nav-item {
      // margin-right: 48px;

      .nav-link {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: var(--text-color);
        padding: 8px 0;
        margin-left: 0px;

        // Extra Small devices (landscape phones, 376px and up)
        @include media-breakpoint-up(xs) {
          padding: 8px 0;
          margin-left: 0px;
        }

        // Small devices (landscape phones, 576px and up)
        @include media-breakpoint-up(sm) {
          padding: 5px 0;
          margin-left: 0px;
        }

        // Medium devices (tablets, 768px and up)
        @include media-breakpoint-up(md) {
          padding: 0;
          margin-left: 15px;
        }

        // Large devices (desktops, 992px and up)
        @include media-breakpoint-up(lg) {
          padding: 0;
          margin-left: 25px;
        }

        // X-Large devices (large desktops, 1200px and up)
        @include media-breakpoint-up(xl) {
          margin-left: 35px;
        }


        &::before {}

        &:hover,
        &:focus,
        &:active {
          color: $primary;

          &::before {}

          svg:not(.arrow),
          svg:not(.arrow) path {
            color: $primary;
            fill: $primary;
          }
        }
      }

      &:focus,
      .nav-link:focus {
        outline: none !important;
      }
    }

    .dark-mode {
      padding: 5px;
      background: none;
      border: 0;
      outline: 0;

      svg {
        width: 20px;
        height: 20px;
      }

      svg {
        fill: rgb(255, 255, 255);
      }
    }
  }
}


// Animation to fade in the dropdown menus
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.85);
  }

  100% {
    transform: scale(1);
  }
}