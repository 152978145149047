 // this helps when not only the button should toggle a dropdown
 [data-toggle]:hover {
   cursor: pointer;
 }

 // remove the caret from Bootstrap by default
 .dropdown-toggle {

   &:after,
   .dropend &:after,
   .dropstart &:before,
   .dropup &:after {
     display: none;
   }
 }