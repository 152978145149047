 @import "./../../Styles/variables";

 .subscribe {
   background: red;

   &__smTitle {
     font-style: normal;
     font-weight: 600;
     color: #FE01E4;
     letter-spacing: 0.45em;
     font-size: 13px;
     line-height: 16px;
     margin-bottom: 10px;

     @include media-breakpoint-up(sm) {
       letter-spacing: 0.50em;
       font-size: 14px;
       line-height: 17px;
       margin-bottom: 11px;
     }

     @include media-breakpoint-up(md) {
       letter-spacing: 0.60em;
       font-size: 15px;
       line-height: 18px;
       margin-bottom: 13px;
     }

     @include media-breakpoint-up(lg) {
       letter-spacing: 0.70em;
       font-size: 15px;
       line-height: 19px;
       margin-bottom: 15px;
     }

     @include media-breakpoint-up(xl) {
       letter-spacing: 0.75em;
       font-size: 16px;
       line-height: 19px;
       margin-bottom: 17px;
     }

     @include media-breakpoint-up(xxl) {}
   }

   &__heading {
     font-style: normal;
     text-transform: capitalize;
     color: var(--text-color);
     font-weight: 600;
     font-size: 26px;
     line-height: 36px;

     @include media-breakpoint-up(sm) {
       font-size: 28px;
       line-height: 38px;
     }

     @include media-breakpoint-up(md) {
       font-size: 30px;
       line-height: 44px;
     }

     @include media-breakpoint-up(lg) {
       font-size: 36px;
       line-height: 46px;
     }

     @include media-breakpoint-up(xl) {
       font-size: 40px;
       line-height: 50px;
     }

     @include media-breakpoint-up(xxl) {
       font-size: 44px;
       line-height: 54px;
     }
   }
 }