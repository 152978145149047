 @import "./../../Styles/variables";

 .about-section {}

 .hero-about {
   &__sub-heading {
     font-family: var(--font-family-sec);
     font-style: normal;
     font-weight: 600;
     color: #FE01E4;
     letter-spacing: 0.45em;
     font-size: 13px;
     line-height: 16px;
     margin-bottom: 10px;

     @include media-breakpoint-up(sm) {
       letter-spacing: 0.50em;
       font-size: 14px;
       line-height: 17px;
       margin-bottom: 11px;
     }

     @include media-breakpoint-up(md) {
       letter-spacing: 0.60em;
       font-size: 15px;
       line-height: 18px;
       margin-bottom: 13px;
     }

     @include media-breakpoint-up(lg) {
       letter-spacing: 0.70em;
       font-size: 15px;
       line-height: 19px;
       margin-bottom: 15px;
     }

     @include media-breakpoint-up(xl) {
       letter-spacing: 0.75em;
       font-size: 16px;
       line-height: 19px;
       margin-bottom: 17px;
     }

     @include media-breakpoint-up(xxl) {}
   }

   &__heading {
     position: relative;
     font-style: normal;
     color: var(--text-color);
     display: inline-block;
     font-weight: 600;
     font-size: 24px;
     line-height: 34px;
     margin-bottom: 20px;

     @include media-breakpoint-up(sm) {
       font-size: 26px;
       line-height: 36px;
       margin-bottom: 22px;
     }

     @include media-breakpoint-up(md) {
       font-size: 34px;
       line-height: 44px;
       margin-bottom: 25px;
     }

     @include media-breakpoint-up(lg) {
       font-size: 36px;
       line-height: 48px;
       margin-bottom: 35px;
     }

     @include media-breakpoint-up(xl) {
       font-size: 40px;
       line-height: 50px;
       margin-bottom: 40px;
     }

     @include media-breakpoint-up(xxl) {
       font-size: 44px;
       line-height: 54px;
       margin-bottom: 55px;
     }
   }

   &__description {
     font-family: var(--font-family-sec);
     color: var(--text-color);
     margin-right: auto;
     margin-left: auto;
     border-left: 8px solid #A733FF;
     font-style: normal;
     font-weight: 500;
     padding-left: 9px;
     font-size: 16px;
     line-height: 28px;

     @include media-breakpoint-up(sm) {
       padding-left: 0 10px;
       font-size: 16px;
       line-height: 28px;
     }

     @include media-breakpoint-up(md) {
       padding-left: 0 10px;
       font-size: 16px;
       line-height: 30px;
     }

     @include media-breakpoint-up(lg) {
       padding-left: 0 12px;
       font-size: 16px;
       line-height: 32px;
     }

     @include media-breakpoint-up(xl) {
       padding-left: 0 12px;
       font-size: 16px;
       line-height: 34px;
     }

     @include media-breakpoint-up(xxl) {
       font-size: 16px;
       line-height: 36px;
     }
   }
 }