 @import "./Styles/variables";

 /* ===== Scrollbar CSS ===== */
 /* Firefox */
 * {
   scrollbar-width: auto;
   scrollbar-color: $primary var(--text-color);
 }

 /* Chrome, Edge, and Safari */
 ::-webkit-scrollbar {
   width: 5px;
   max-width: 12px;
   background-color: #F5F5F5;


   // Extra Small devices (landscape phones, 376px and up)
   @include media-breakpoint-up(xs) {
     width: 5px;
   }

   // Small devices (landscape phones, 576px and up)
   @include media-breakpoint-up(sm) {
     width: 7px;
   }

   // Medium devices (tablets, 768px and up)
   @include media-breakpoint-up(md) {
     width: 8px;
   }

   // Large devices (desktops, 992px and up)
   @include media-breakpoint-up(lg) {
     width: 10px;
   }

   // X-Large devices (large desktops, 1200px and up)
   @include media-breakpoint-up(xl) {}
 }

 ::-webkit-scrollbar-track {
   border-radius: 15px;
   background-color: #F5F5F5;
   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
 }

 /* Handle */
 ::-webkit-scrollbar-thumb {
   border-radius: 15px;
   border: 1px solid var(--text-color);
   box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
   background-color: $primary;

   &:hover {
     border-radius: 15px;
     background-color: darken($primary, 20%);
   }
 }

 .app-loader {
   background: var(--bg-gradient-1);
   display: grid;
   place-items: center;
   min-height: 100vh;
 }

 // MAP Overrides
 .gm-style .gm-style-iw-d {
   max-height: 300px !important;
 }

 .gm-style .gm-style-iw-d,
 .gm-style .gm-style-iw-c {
   padding: 0px !important;
   border-radius: 15px !important;
   overflow: hidden !important;
   background: transparent;
   background: #1A0E25 !important;
 }

 .gm-style .gm-style-iw-c {}

 .gm-style-iw-tc {
   &::after {}
 }

 button.gm-ui-hover-effect[title="Close"] {
   width: 24px !important;
   height: 24px !important;
   top: 0px !important;
   right: 4px !important;
 }

 .splide__track--nav>.splide__list>.splide__slide.is-active {
   border: 0 !important;
 }