@import "./theme/mixins";
/**
 * = Typography
 */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: 100% 40vh;
  background-position: 5px 0px, 0px 0px, 0px 0px;
  // background-image: radial-gradient(45% 20% at 3% 61%, #C301AF63 29%, #073AFF00 100%), radial-gradient(39% 18% at 91% 33%, #9100FF5E 30%, #073AFF00 100%), linear-gradient(152deg, #281637 1%, #281637 100%);
  background-image: var(--bs-body-bg-gradient);

  // Extra Small devices (landscape phones, 376px and up)
  @include media-breakpoint-up(xs) {
    background-size: 100% 50vh;
  }

  // Small devices (landscape phones, 576px and up)
  @include media-breakpoint-up(sm) {
    background-size: 100% 70vh;
  }

  // Medium devices (tablets, 768px and up)
  @include media-breakpoint-up(md) {
    background-size: 100% 100vh;
  }

  // Large devices (desktops, 992px and up)
  @include media-breakpoint-up(lg) {
    background-size: 100% 120vh;
  }

  // X-Large devices (large desktops, 1200px and up)
  @include media-breakpoint-up(xl) {}
}

html {
  // --section-background-color: linear-gradient(to bottom left,
  //     rgba(17, 16, 16, 0.582),
  //     rgba(12, 8, 24, 0.904));

  // --image-gradient: linear-gradient(to bottom left,
  //     rgba(17, 16, 16, 0.678),
  //     rgba(12, 10, 22, 0.863));
}

html,
body,
p {
  --bs-body-font-size: 12.5px;
  font-size: 12.5px;

  // Extra Small devices (landscape phones, 376px and up)
  @include media-breakpoint-up(xs) {
    --bs-body-font-size: 13px;
    font-size: 13px;
  }

  // Small devices (landscape phones, 576px and up)
  @include media-breakpoint-up(sm) {
    --bs-body-font-size: 14px;
    font-size: 14px;
  }

  // Medium devices (tablets, 768px and up)
  @include media-breakpoint-up(md) {
    --bs-body-font-size: 15px;
    font-size: 15px;
  }

  // Large devices (desktops, 992px and up)
  @include media-breakpoint-up(lg) {
    --bs-body-font-size: 16px;
    font-size: 16px;
  }

  // X-Large devices (large desktops, 1200px and up)
  @include media-breakpoint-up(xl) {
    --bs-body-font-size: 16px;
    font-size: 16px;
  }
}


iframe {
  border: 0;
}

figcaption,
figure,
main {
  display: block;
  margin: 0;
}

main {
  overflow: hidden;
}

img {
  max-width: 100%;
}

strong {
  font-weight: $font-weight-bold;
}

button:focus {
  outline: 0;
}

label {
  font-weight: $font-weight-bold;
  margin-bottom: .5rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {}

p,
ol li,
ul li,
.p {
  font-style: normal;
  font-weight: 400;
}

a,
.text-action {
  color: $link-color;
  transition: $transition-base;

  &:hover {
    color: $link-hover-color;
    cursor: pointer;
  }
}

.lead {}

.line-through {
  -webkit-text-decoration: line-through;
  text-decoration: line-through;
}

.text-italic {
  font-style: italic !important;
}

// underline on hover

.hover\:underline {
  &:hover {
    text-decoration: underline;
  }
}

// Headings

.heading {
  font-size: $h2-font-size;
  font-weight: $font-weight-bold;
}



// Blockquotes
.blockquote {
  font-style: italic;

  &.blockquote-large {

    @include media-breakpoint-down(md) {}
  }

  .blockquote-footer {

    @include media-breakpoint-down(md) {}
  }
}


//PrismJs text	

code[class*="language-"],
pre[class*="language-"] {
  text-shadow: none;
}

article {
  img {
    margin: 40px 0;
  }

  p,
  ul,
  ol,
  blockquote {
    margin-bottom: 20px;
  }

  p,
  ul li,
  ol li {
    font-size: 1.2rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    margin-bottom: 30px;
  }
}

.folder-structure li {
  font-size: 1.2rem;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.text {
  font-style: normal;
  font-weight: 400;

  &-xxs {
    font-size: 12px;
    line-height: 16px;

    // Extra Small devices (landscape phones, 376px and up)
    @include media-breakpoint-up(xs) {}

    // Small devices (landscape phones, 576px and up)
    @include media-breakpoint-up(sm) {
      font-size: 12px;
      line-height: 16px;
    }

    // Medium devices (tablets, 768px and up)
    @include media-breakpoint-up(md) {
      font-size: 12px;
      line-height: 16px;
    }

    // Large devices (desktops, 992px and up)
    @include media-breakpoint-up(lg) {
      font-size: 12px;
      line-height: 16px;
    }

    // X-Large devices (large desktops, 1200px and up)
    @include media-breakpoint-up(xl) {}
  }

  &-xs {
    font-size: 12px;
    line-height: 16px;

    // Extra Small devices (landscape phones, 376px and up)
    @include media-breakpoint-up(xs) {}

    // Small devices (landscape phones, 576px and up)
    @include media-breakpoint-up(sm) {
      font-size: 12.5px;
      line-height: 16px;
    }

    // Medium devices (tablets, 768px and up)
    @include media-breakpoint-up(md) {
      font-size: 13px;
      line-height: 16px;
    }

    // Large devices (desktops, 992px and up)
    @include media-breakpoint-up(lg) {
      font-size: 13px;
      line-height: 16px;
    }

    // X-Large devices (large desktops, 1200px and up)
    @include media-breakpoint-up(xl) {}
  }

  &-sm {
    font-size: 12px;
    line-height: 16px;

    // Extra Small devices (landscape phones, 376px and up)
    @include media-breakpoint-up(xs) {}

    // Small devices (landscape phones, 576px and up)
    @include media-breakpoint-up(sm) {
      font-size: 12px;
      line-height: 16px;
    }

    // Medium devices (tablets, 768px and up)
    @include media-breakpoint-up(md) {
      font-size: 12px;
      line-height: 16px;
    }

    // Large devices (desktops, 992px and up)
    @include media-breakpoint-up(lg) {
      font-size: 12px;
      line-height: 16px;
    }

    // X-Large devices (large desktops, 1200px and up)
    @include media-breakpoint-up(xl) {}
  }

  &-md {
    font-size: 12px;
    line-height: 16px;

    // Extra Small devices (landscape phones, 376px and up)
    @include media-breakpoint-up(xs) {}

    // Small devices (landscape phones, 576px and up)
    @include media-breakpoint-up(sm) {
      font-size: 12px;
      line-height: 16px;
    }

    // Medium devices (tablets, 768px and up)
    @include media-breakpoint-up(md) {
      font-size: 12px;
      line-height: 16px;
    }

    // Large devices (desktops, 992px and up)
    @include media-breakpoint-up(lg) {
      font-size: 12px;
      line-height: 16px;
    }

    // X-Large devices (large desktops, 1200px and up)
    @include media-breakpoint-up(xl) {}
  }

  &-lg {
    font-size: 12px;
    line-height: 16px;

    // Extra Small devices (landscape phones, 376px and up)
    @include media-breakpoint-up(xs) {}

    // Small devices (landscape phones, 576px and up)
    @include media-breakpoint-up(sm) {
      font-size: 12px;
      line-height: 16px;
    }

    // Medium devices (tablets, 768px and up)
    @include media-breakpoint-up(md) {
      font-size: 12px;
      line-height: 16px;
    }

    // Large devices (desktops, 992px and up)
    @include media-breakpoint-up(lg) {
      font-size: 12px;
      line-height: 16px;
    }

    // X-Large devices (large desktops, 1200px and up)
    @include media-breakpoint-up(xl) {}
  }

  &-xl {
    font-size: 12px;
    line-height: 16px;

    // Extra Small devices (landscape phones, 376px and up)
    @include media-breakpoint-up(xs) {}

    // Small devices (landscape phones, 576px and up)
    @include media-breakpoint-up(sm) {
      font-size: 12px;
      line-height: 16px;
    }

    // Medium devices (tablets, 768px and up)
    @include media-breakpoint-up(md) {
      font-size: 12px;
      line-height: 16px;
    }

    // Large devices (desktops, 992px and up)
    @include media-breakpoint-up(lg) {
      font-size: 12px;
      line-height: 16px;
    }

    // X-Large devices (large desktops, 1200px and up)
    @include media-breakpoint-up(xl) {}
  }
}

// FONT FAMILY
.font-sec {
  font-family: var(--font-family-sec);
}

// COLORs & BGs 
.text-light {
  @include dark(color, #fff, #281637, "!important");
}

.text-white {
  @include dark(color, #F2F2F2, #1A0F24, "!important");
}

.text-primary {
  @include dark(color, $primary, $primary, "!important");
}