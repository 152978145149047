 @import "./../../Styles/variables";
 $product-border-radius: 25px;

 .product {
   $root: &;
   height: auto;
   border-radius: $product-border-radius;
   display: block;
   z-index: 0;
   margin-top: 30px;
   margin-bottom: 30px;

   &__tilt {
     border-radius: $product-border-radius;
   }

   &__inner-wrapper {
     padding: 1px;
     border-radius: $product-border-radius;
     background: linear-gradient(#1A0F24 100%, #1A0F24 100%);

     #{$root}:hover &,
     #{$root}:focus &,
     #{$root}:active & {
       background: linear-gradient(#C301AF 100%, #9100FF 100%);
     }
   }

   &__body {
     position: relative;
     background: var(--dark-2);
     width: 100%;
     height: 100%;
     z-index: 1;
     border-radius: $product-border-radius;
     padding: 30px 6px;

     // Extra Small devices (landscape phones, 376px and up)
     @include media-breakpoint-up(xs) {
       padding: 40px 10px;
     }

     // Small devices (landscape phones, 576px and up)
     @include media-breakpoint-up(sm) {
       padding: 50px 10px;
     }

     // Medium devices (tablets, 768px and up)
     @include media-breakpoint-up(md) {
       padding: 55px 12px;
     }

     // Large devices (desktops, 992px and up)
     @include media-breakpoint-up(lg) {
       padding: 60px 13px;
     }

     // X-Large devices (large desktops, 1200px and up)
     @include media-breakpoint-up(xl) {
       padding: 60px 15px;
     }

     #{$root}:hover &,
     #{$root}:focus &,
     #{$root}:active & {
       background: rgba(darken(#1A0E25, 15%), 1.5);
       background: darken(#1A0E25, 2%);
     }
   }

   &__link {
     background: linear-gradient(97.3deg, #C301AF 20.94%, #9100FF 80.42%);
     border-radius: 24px;
     padding: 11px 25px;
     text-decoration: none;
     position: absolute;
     left: 50%;
     top: 50%;
     transform: translate(-50%, -50%);
     min-width: 160px;
     opacity: 0;
     // width: 100%;

     // Extra Small devices (landscape phones, 376px and up)
     @include media-breakpoint-up(xs) {}

     // Small devices (landscape phones, 576px and up)
     @include media-breakpoint-up(sm) {}

     // Medium devices (tablets, 768px and up)
     @include media-breakpoint-up(md) {}

     // Large devices (desktops, 992px and up)
     @include media-breakpoint-up(lg) {}

     // X-Large devices (large desktops, 1200px and up)
     @include media-breakpoint-up(xl) {}

     #{$root}:hover &,
     #{$root}:focus &,
     #{$root}:active & {
       opacity: 1;
     }
   }

   &__avatar {
     filter: drop-shadow(0px 4px 100px rgba(0, 0, 0, 0.25));
     object-fit: contain;
     width: 80px;
     min-height: 170px;
     width: 90%;

     // Extra Small devices (landscape phones, 376px and up)
     @include media-breakpoint-up(xs) {
       width: 90px;
       min-height: 180px;
       width: 90%;
     }

     // Small devices (landscape phones, 576px and up)
     @include media-breakpoint-up(sm) {
       width: 104px;
       min-height: 190px;
       width: 90%;

     }

     // Medium devices (tablets, 768px and up)
     @include media-breakpoint-up(md) {
       width: 114px;
       min-height: 200px;
       width: 90%;
     }

     // Large devices (desktops, 992px and up)
     @include media-breakpoint-up(lg) {
       width: 130px;
       min-height: 210px;
       width: 90%;
     }

     // X-Large devices (large desktops, 1200px and up)
     @include media-breakpoint-up(xl) {
       width: 144px;
       min-height: 220px;
       width: 90%;
     }

     #{$root}:hover &,
     #{$root}:focus &,
     #{$root}:active & {
       filter: brightness(0.5);
     }
   }

   &__footer {
     margin: 23px 0;
     background: transparent;
     display: flex;
     justify-content: space-between;
     align-items: center;
   }

   &__name {
     margin-bottom: 0;
     font-style: normal;
     text-transform: capitalize;
     color: var(--text-color);
     font-weight: 600;
     font-size: 14px;
     line-height: 18px;

     // Extra Small devices (landscape phones, 376px and up)
     @include media-breakpoint-up(xs) {
       font-size: 14px;
       line-height: 20px;
     }

     // Small devices (landscape phones, 576px and up)
     @include media-breakpoint-up(sm) {
       font-size: 15px;
       line-height: 20px;
     }

     // Medium devices (tablets, 768px and up)
     @include media-breakpoint-up(md) {
       font-size: 16px;
       line-height: 20px;
     }

     // Large devices (desktops, 992px and up)
     @include media-breakpoint-up(lg) {
       font-size: 16px;
       line-height: 20px;
     }

     // X-Large devices (large desktops, 1200px and up)
     @include media-breakpoint-up(xl) {
       font-size: 16px;
       line-height: 20px;
     }
   }

   &__price {
     margin-bottom: 0;
     border: 1px solid #FE1BE6;
     border-radius: 5px;
     // font-family: 'Gilroy-SemiBold';
     font-size: 12px;
     line-height: 12px;
     color: var(--text-color);
     padding: 7px 13px;

     #{$root}:hover &,
     #{$root}:focus &,
     #{$root}:active & {
       box-shadow: 1px 1px 10px 2px rgba($primary, 0.5);
     }
   }

   &:hover,
   &:focus,
   &:active {}
 }

 .products-splide {

   .splide__pagination {
     bottom: -30px;

     li {

       .splide__pagination__page {
         width: 12px;
         height: 12px;
         border-radius: 50%;
         background: #000000;

         &.is-active {
           background: linear-gradient(180deg, #C301AF 0%, #9100FF 100%);
         }
       }
     }
   }

   .splide__track--nav>.splide__list>.splide__slide {
     border-width: 0px !important;
     opacity: 1;

     &.is-active {}
   }
 }

 .custom-wrapper {}

 .buying-slider-arrows {
   .splide {

     &__arrows {
       display: flex;
       align-items: center;
       justify-content: flex-end;
     }

     &__arrow {
       transform: translateY(0%) !important;
       position: static !important;

       &--next {
         margin-left: 35px;
       }

       &--prev {}
     }
   }
 }