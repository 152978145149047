 @import "./../../../../Styles/variables";
 @import "./../../../../Styles/theme/mixins";

 .mapContainerStyle {
   height: "92vh";
   width: "100vw";
 }

 .option-wrapper {
   position: absolute;
   top: 7rem;
   left: 1rem;
   z-index: 3;
   display: flex;
   align-items: center;
 }

 .search {
   width: 100%;
   width: min-content;
   //  width: max-content;
   flex: 2 1 auto;
   position: relative;
   z-index: 10;
   border: 0;
   border-radius: 15px;
   padding: 2px;
   outline: none;

   &__icon {
     position: absolute;
     left: 15px;
     top: 14px;
     z-index: 11;
   }

   .input,
   &__input {
     z-index: 10;
     height: 47px;
     padding: 0 35px;
     padding-left: 50px;
     font-size: 15px;
     max-width: 100%;
     outline: none;
     box-shadow: none;
     position: relative;
     background: #FFFFFF;
     border-radius: 15px;
     border: 2px solid #FE01E4;
     //  background: linear-gradient(180deg, #FE01E4 0%, #A733FF 100%);
     box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.5);
   }
 }

 .locate {
   width: 55px;
   height: 50px;
   padding: 10px 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   background: linear-gradient(180deg, #C301AF 0%, #9100FF 100%);
   box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.5);
   border-radius: 15px;
   border: none;
   z-index: 10;

   &__icon {
     height: 23px;
   }

   img {
     width: 30px;
     cursor: pointer;
   }
 }

 .combobox {}

 .nfts-list {
   overflow-y: scroll;
   padding-right: 10px;
   height: 90vh;

   /* Chrome, Edge, and Safari */
   &::-webkit-scrollbar {
     width: 1px;
     background-color: var(--dark-2);
   }

   &::-webkit-scrollbar-track {
     background-color: var(--dark-2);
     box-shadow: inset 0 0 6px var(--dark-2);
   }
 }

 .search-text {

   &__total {
     font-style: normal;
     font-weight: 400;
     font-size: 18px;
     //  line-height: 24px;
     text-transform: capitalize;
     font-family: var(--font-family-sec);
     color: var(--text-color);
     color: #FFFFFF;
   }

   &__text1,
   &__text2 {
     font-weight: 100;
     font-size: 16px;
     //  line-height: 24px;
     letter-spacing: 0.01em;
     font-family: var(--font-family-primary);
     color: #FFFFFF;
   }
 }

 