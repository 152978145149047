 @import "./../../Styles/variables";

 .text-image {
   &__sub-heading {
     font-family: var(--font-family-sec);
     font-style: normal;
     font-weight: 600;
     color: #FE01E4;
     letter-spacing: 0.45em;
     font-size: 13px;
     line-height: 16px;
     margin-bottom: 15px;

     @include media-breakpoint-up(sm) {
       letter-spacing: 0.50em;
       font-size: 14px;
       line-height: 17px;
       margin-bottom: 15px;
     }

     @include media-breakpoint-up(md) {
       letter-spacing: 0.60em;
       font-size: 15px;
       line-height: 18px;
       margin-bottom: 16px;
     }

     @include media-breakpoint-up(lg) {
       letter-spacing: 0.70em;
       font-size: 15px;
       line-height: 19px;
       margin-bottom: 20px;
     }

     @include media-breakpoint-up(xl) {
       letter-spacing: 0.75em;
       font-size: 16px;
       line-height: 19px;
       margin-bottom: 22px;
     }

     @include media-breakpoint-up(xxl) {}
   }

   &__heading {
     position: relative;
     font-style: normal;
     color: var(--text-color);
     display: inline-block;
     font-weight: 600;
     font-size: 26px;
     line-height: 36px;
     margin-bottom: 14px;

     @include media-breakpoint-up(sm) {
       font-size: 28px;
       line-height: 40px;
       margin-bottom: 16px;
     }

     @include media-breakpoint-up(md) {
       font-size: 32px;
       line-height: 40px;
       margin-bottom: 22px;
     }

     @include media-breakpoint-up(lg) {
       font-size: 36px;
       line-height: 46px;
       margin-bottom: 26px;
     }

     @include media-breakpoint-up(xl) {
       font-size: 40px;
       line-height: 50px;
       margin-bottom: 30px;
     }

     @include media-breakpoint-up(xxl) {
       font-size: 44px;
       line-height: 54px;
       margin-bottom: 34px;
     }
   }

   &__description {
     position: relative;
     font-family: var(--font-family-sec);
     color: var(--text-color);
     margin-right: auto;
     margin-left: auto;
     font-style: normal;
     font-weight: 500;
     padding: 0 5px;
     font-size: 16px;
     line-height: 32px;

     &::after {
       content: "";
       height: 35px;
       width: 5px;
       background: #9100FF;
       position: absolute;
       top: 0;
       left: -10px;
       bottom: 15px;
       z-index: -1;

       @include media-breakpoint-up(sm) {
         left: -10px;
       }

       @include media-breakpoint-up(md) {
         left: -12px;
       }

       @include media-breakpoint-up(lg) {
         left: -15px;
       }

       @include media-breakpoint-up(xl) {
         left: -20px;
       }

       @include media-breakpoint-up(xxl) {
         left: -22px;
       }
     }

     @include media-breakpoint-up(sm) {
       padding: 0 10px;
       font-size: 16px;
       line-height: 32px;
     }

     @include media-breakpoint-up(md) {
       padding: 0 12px;
       font-size: 16px;
       line-height: 32px;
     }

     @include media-breakpoint-up(lg) {
       padding: 0 15px;
       font-size: 16px;
       line-height: 32px;
     }

     @include media-breakpoint-up(xl) {
       padding: 0 20px;
       font-size: 16px;
       line-height: 32px;
     }

     @include media-breakpoint-up(xxl) {
       padding: 0 22px;
     }
   }
 }