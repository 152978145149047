.form-check-input {
  &.round-check {
    &[type="checkbox"] {
      @include border-radius($form-check-radio-border-radius);
    }
  }
}

.form-switch {

  .form-check-input {
    height: 1.275em;
  }
}