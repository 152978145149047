 @import "./../../Styles/variables";
 @import "./../../Styles/theme/mixins";

 .pageTitle {
   color: var(--white);
   font-weight: 600;
   font-size: 36px;
   line-height: 44px;
 }

 .allItems {
   color: var(--white);
   font-weight: 600;
   font-size: 16px;
   line-height: 20px;
 }

 .blockTitle {
   color: var(--white);
   font-weight: 600;
   text-align: center;
   font-size: 36px;
   line-height: 44px;
   margin-bottom: 25px;
 }

 .filterBtn {
   background: #0E0019;
   border-radius: 15px;
   color: var(--text-color);
   font-weight: 600;
   border: 2px solid #9100FF;
   padding: 10px 12px;
   font-size: 14px;
   line-height: 17px;
 }

 .hr {
   margin: 20px 0;
   border: 1px solid var(--text-color);
 }

 // INPUT
 .input {
   background: #0E0019;
   border-radius: 20px;
   border: 2px solid #9100FF;
   max-width: 347px;
   padding: 10px 10px;
   display: flex;
   align-items: center;
   gap: 0;

   &Text {
     border: 0;
     background: #0E0019;
     display: inline-block;
   }

   &Control {
     margin-left: 10px;
     border: 0;
     width: 100%;
     color: var(--text-color);
     background: #0E0019;

     @include placeholder {
       color: var(--text-color);
       font-weight: 500;
       font-size: 14px;
       line-height: 17px;
     }

     &:focus,
     &:hover,
     &:active {
       border: 0;
       outline: none;
       color: var(--text-color);
       background: #0E0019;
     }
   }
 }

 // NAVs
 .nav {
   list-style: none;

   &Item {
     display: inline-block;
   }

   &Link {
     border: 0;
     outline: none;
     background: transparent;
     color: var(--text-color);
     font-weight: 600;
     padding: 8px 12px;
     font-size: 13px;
     line-height: 17px;

     // Extra Small devices (landscape phones, 376px and up)
     @include media-breakpoint-up(xs) {
       padding: 8px 14px;
       font-size: 13px;
       line-height: 17px;
     }

     // Small devices (landscape phones, 576px and up)
     @include media-breakpoint-up(sm) {
       padding: 8px 15px;
       font-size: 14px;
       line-height: 18px;
     }

     // Medium devices (tablets, 768px and up)
     @include media-breakpoint-up(md) {
       padding: 8px 16px;
       font-size: 15px;
       line-height: 19px;
     }

     // Large devices (desktops, 992px and up)
     @include media-breakpoint-up(lg) {
       padding: 8px 18px;
       font-size: 16px;
       line-height: 20px;
     }

     // X-Large devices (large desktops, 1200px and up)
     @include media-breakpoint-up(xl) {
       padding: 8px 20px;
     }

     &Active {
       color: var(--text-color);
       background: linear-gradient(180deg, #9100FF 0%, #C301AF 100%);
       border-radius: 24px;
     }
   }
 }