.form-group {
  margin-bottom: 10px;

  // Extra Small devices (landscape phones, 376px and up)
  @include media-breakpoint-up(xs) {
    margin-bottom: 15px;
  }

  // Small devices (landscape phones, 576px and up)
  @include media-breakpoint-up(sm) {
    margin-bottom: 20px;
  }

  // Medium devices (tablets, 768px and up)
  @include media-breakpoint-up(md) {
    margin-bottom: 23px;
  }

  // Large devices (desktops, 992px and up)
  @include media-breakpoint-up(lg) {
    margin-bottom: 25px;
  }

  // X-Large devices (large desktops, 1200px and up)
  @include media-breakpoint-up(xl) {
    margin-bottom: 27px;
  }
}

input.form-control,
textarea.form-control,
select.form-select,
.input-group-text {

  // Extra Small devices (landscape phones, 376px and up)
  @include media-breakpoint-up(xs) {}

  // Small devices (landscape phones, 576px and up)
  @include media-breakpoint-up(sm) {}

  // Medium devices (tablets, 768px and up)
  @include media-breakpoint-up(md) {}

  // Large devices (desktops, 992px and up)
  @include media-breakpoint-up(lg) {}

  // X-Large devices (large desktops, 1200px and up)
  @include media-breakpoint-up(xl) {}


  &:focus,
  &:active,
  &:hover {}


  // Medium devices (tablets, 768px and up)
  @include media-breakpoint-up(md) {
    &.form-control-lg {}
  }

  // Large devices (desktops, 992px and up)
  @include media-breakpoint-up(lg) {
    &.form-control-lg {}
  }
}

.form-control-wrapper {}

textarea.form-control {
  border-radius: 23px;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #150535 inset !important;
  -webkit-text-fill-color: white !important;
}