 @import "./../../../../Styles/variables";

 .info-nft {
   max-width: 260px;
   background: #fff;
   border-radius: 15px;
   padding: 0px;
   overflow: hidden;

   &__media {
     object-fit: cover;
     padding: 0px;
     min-height: 105px !important;
    //  height: 95px !important;
   }

   &__body {
     padding: 15px;
     display: inline-block;
     text-decoration: none;
   }

   &__icon {
     height: 18px;
     width: 18px;
     object-fit: contain;
     margin-right: 5px;
   }

   &__name {
     font-weight: 600;
     font-size: 14px;
     line-height: 20px;
     color: #000;
     margin-bottom: 12px;
   }

   &__address {
     display: flex;
     align-items: center;
     font-weight: 500;
     font-size: 13px;
     line-height: 24px;
     letter-spacing: 0.01em;
     font-family: var(--font-family-sec);
     color: #000;
     margin-bottom: 6px;
   }

   &__map_level {
     display: flex;
     align-items: center;
     font-weight: 500;
     font-size: 13px;
     line-height: 24px;
     letter-spacing: 0.01em;
     color: #000;
     margin-bottom: 7px;
   }

   &__price {
     display: flex;
     align-items: center;
     font-weight: 600;
     font-size: 13px;
     line-height: 24px;
     letter-spacing: 0.01em;
     color: #000;
     margin-bottom: 7px;
   }

   &__owned-by {
     font-weight: 500;
     font-size: 13px;
     line-height: 24px;
     letter-spacing: 0.01em;
     font-family: var(--font-family-sec);
     color: #000;
     margin-bottom: 0px;

     b {
       font-weight: 600;
       font-family: var(--bs-body-font-family);
       color: #000;
     }
   }
 }

 .arrows {}

 .arrow {
   background: #fff;
   border-radius: 50%;
   padding: 5px;
   width: 24px;
   height: 24px;
 }