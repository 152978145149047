@mixin placeholder {
  &::-webkit-input-placeholder {
    @content
  }

  &:-moz-placeholder {
    @content
  }

  &::-moz-placeholder {
    @content
  }

  &:-ms-input-placeholder {
    @content
  }
}

@mixin var($property, $varCssVal, $varName) {
  // #{$property}: var(--#{$varName});
  #{$property}: var(--#{$varCssVal}, #{$varName});
}

@mixin var($property, $varCssVal, $varName) {
  #{$property}: var(--#{$varCssVal}, #{$varName});
}

@mixin dark($property, $darkVal, $lightVal, $imp: "") {
  @at-root #{selector-nest('.dark-mode', &)} {
    #{$property}: #{$darkVal} #{$imp};
  }

  @at-root #{selector-nest(':root', &)} {
    #{$property}: #{$darkVal} #{$imp};
  }

  @at-root #{selector-nest('.light-mode', &)} {
    #{$property}: #{$lightVal} #{$imp};
  }
}