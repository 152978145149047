/**
 * = Bootstrap carousels
 */

.swiper-pagination-bullet {
  background-color: $primary;
  opacity: 1;
  border-radius: 0px;
  height: 5px;
  width: 20px;

  &.swiper-pagination-bullet-active {
    background-color: $primary-active;
  }
}

// .swiper-btn {
//   svg {
//     fill: #0EA980 !important;

//     path {
//       fill: #0EA980 !important;
//     }
//   }

//   &.swiper-button-disabled {
//     svg {
//       fill: #D7D7D7 !important;

//       path {
//         fill: #D7D7D7 !important;
//       }
//     }
//   }

//   &-prev {}

//   &-next {}
// }