 @import "./../../../../Styles/variables";
 $teams-border-radius: 25px;

 .teams {
   &__sub-heading {
     font-family: var(--font-family-sec);
     font-style: normal;
     font-weight: 600;
     color: #FE01E4;
     letter-spacing: 0.45em;
     font-size: 13px;
     line-height: 16px;
     margin-bottom: 10px;

     @include media-breakpoint-up(sm) {
       letter-spacing: 0.50em;
       font-size: 14px;
       line-height: 17px;
       margin-bottom: 11px;
     }

     @include media-breakpoint-up(md) {
       letter-spacing: 0.60em;
       font-size: 15px;
       line-height: 18px;
       margin-bottom: 13px;
     }

     @include media-breakpoint-up(lg) {
       letter-spacing: 0.70em;
       font-size: 15px;
       line-height: 19px;
       margin-bottom: 15px;
     }

     @include media-breakpoint-up(xl) {
       letter-spacing: 0.75em;
       font-size: 16px;
       line-height: 19px;
       margin-bottom: 17px;
     }

     @include media-breakpoint-up(xxl) {}
   }

   &__heading {
     position: relative;
     font-style: normal;
     color: var(--text-color);
     display: inline-block;
     font-weight: 600;
     font-size: 26px;
     line-height: 36px;
     margin-bottom: 14px;

     @include media-breakpoint-up(sm) {
       font-size: 28px;
       line-height: 40px;
       margin-bottom: 16px;
     }

     @include media-breakpoint-up(md) {
       font-size: 32px;
       line-height: 40px;
       margin-bottom: 18px;
     }

     @include media-breakpoint-up(lg) {
       font-size: 36px;
       line-height: 46px;
       margin-bottom: 18px;
     }

     @include media-breakpoint-up(xl) {
       font-size: 40px;
       line-height: 50px;
       margin-bottom: 20px;
     }

     @include media-breakpoint-up(xxl) {
       font-size: 44px;
       line-height: 54px;
       margin-bottom: 20px;
     }
   }

   &__description {
     font-family: var(--font-family-sec);
     color: var(--text-color);
     margin-right: auto;
     margin-left: auto;
     border-left: 8px solid #A733FF;
     font-style: normal;
     font-weight: 500;
     padding: 0 5px;
     font-size: 16px;
     line-height: 23px;
     letter-spacing: 0.02em;
     width: 100%;

     @include media-breakpoint-up(sm) {
       padding: 0 10px;
       font-size: 17px;
       line-height: 23px;
       letter-spacing: 0.02em;
       width: 95%;
     }

     @include media-breakpoint-up(md) {
       padding: 0 12px;
       font-size: 18px;
       line-height: 24px;
       letter-spacing: 0.02em;
       width: 90%;
     }

     @include media-breakpoint-up(lg) {
       padding: 0 15px;
       font-size: 19px;
       line-height: 24px;
       letter-spacing: 0.02em;
       width: 90%;
     }

     @include media-breakpoint-up(xl) {
       padding: 0 20px;
       font-size: 20px;
       line-height: 24px;
       letter-spacing: 0.02em;
       width: 85%;
     }

     @include media-breakpoint-up(xxl) {
       padding: 0 22px;
       font-size: 20px;
       line-height: 24px;
       letter-spacing: 0.02em;
       width: 75%;
     }
   }
 }

 .team {
   //  width: 300px;
   height: auto;
   background: linear-gradient(#C301AF 100%, #9100FF 100%);
   border-radius: $teams-border-radius;
   display: block;
   padding: 1px;
   z-index: 0;
   margin-top: 30px;
   margin-bottom: 30px;

   &__tilt {
     border-radius: $teams-border-radius;
     background: linear-gradient(#C301AF 100%, #9100FF 100%);
   }

   &__inner-wrapper {
     background-color: var(--dark-2);
     width: 100%;
     height: 100%;
     z-index: 1;
     border-radius: 25px;
     padding: 30px 15px;

     // Extra Small devices (landscape phones, 376px and up)
     @include media-breakpoint-up(xs) {
       padding: 40px 20px;
     }

     // Small devices (landscape phones, 576px and up)
     @include media-breakpoint-up(sm) {
       padding: 50px 25px;
     }

     // Medium devices (tablets, 768px and up)
     @include media-breakpoint-up(md) {
       padding: 60px 30px;
     }

     // Large devices (desktops, 992px and up)
     @include media-breakpoint-up(lg) {
       padding: 70px 30px;
     }

     // X-Large devices (large desktops, 1200px and up)
     @include media-breakpoint-up(xl) {
       padding: 70px 30px;
     }
   }

   &__avatar {
     filter: drop-shadow(0px 4px 100px rgba(0, 0, 0, 0.25));
     width: 80px;
     height: 80px;
     margin-bottom: 22px;

     // Extra Small devices (landscape phones, 376px and up)
     @include media-breakpoint-up(xs) {
       width: 90px;
       height: 90px;
       margin-bottom: 24px;
     }

     // Small devices (landscape phones, 576px and up)
     @include media-breakpoint-up(sm) {
       width: 104px;
       height: 104px;
       margin-bottom: 30px;

     }

     // Medium devices (tablets, 768px and up)
     @include media-breakpoint-up(md) {
       width: 114px;
       height: 114px;
       margin-bottom: 34px;
     }

     // Large devices (desktops, 992px and up)
     @include media-breakpoint-up(lg) {
       width: 130px;
       height: 130px;
       margin-bottom: 40px;
     }

     // X-Large devices (large desktops, 1200px and up)
     @include media-breakpoint-up(xl) {
       width: 144px;
       height: 144px;
       margin-bottom: 44px;
     }
   }

   &__name {
     color: var(--text-color);
     font-weight: 600;
     font-size: 15px;
     line-height: 18px;

     // Extra Small devices (landscape phones, 376px and up)
     @include media-breakpoint-up(xs) {
       font-size: 16px;
       line-height: 20px;
     }

     // Small devices (landscape phones, 576px and up)
     @include media-breakpoint-up(sm) {
       font-size: 17px;
       line-height: 21px;
     }

     // Medium devices (tablets, 768px and up)
     @include media-breakpoint-up(md) {
       font-size: 18px;
       line-height: 22px;
     }

     // Large devices (desktops, 992px and up)
     @include media-breakpoint-up(lg) {
       font-size: 19px;
       line-height: 23px;
     }

     // X-Large devices (large desktops, 1200px and up)
     @include media-breakpoint-up(xl) {
       font-size: 20px;
       line-height: 25px;
     }
   }

   &__designation {
     font-weight: 500;
     font-size: 16px;
     line-height: 36px;
     color: var(--text-color);
   }
 }

 .teams-splide {

   .splide__pagination {
     bottom: -30px;

     li {

       .splide__pagination__page {
         width: 12px;
         height: 12px;
         border-radius: 50%;
         background: #000000;

         &.is-active {
           background: linear-gradient(180deg, #C301AF 0%, #9100FF 100%);
         }
       }
     }
   }

   .splide__track--nav>.splide__list>.splide__slide {
     border-width: 0px !important;
     opacity: 1;

     &.is-active {}
   }
 }