 @import "./../../../../Styles/variables";
 $roadmap-border-radius: 25px;

 .roadmap {
   &__sub-heading {
     font-family: var(--font-family-sec);
     font-style: normal;
     font-weight: 600;
     color: #FE01E4;
     letter-spacing: 0.45em;
     font-size: 13px;
     line-height: 16px;
     margin-bottom: 10px;

     @include media-breakpoint-up(sm) {
       letter-spacing: 0.50em;
       font-size: 14px;
       line-height: 17px;
       margin-bottom: 11px;
     }

     @include media-breakpoint-up(md) {
       letter-spacing: 0.60em;
       font-size: 15px;
       line-height: 18px;
       margin-bottom: 13px;
     }

     @include media-breakpoint-up(lg) {
       letter-spacing: 0.70em;
       font-size: 15px;
       line-height: 19px;
       margin-bottom: 15px;
     }

     @include media-breakpoint-up(xl) {
       letter-spacing: 0.75em;
       font-size: 16px;
       line-height: 19px;
       margin-bottom: 17px;
     }

     @include media-breakpoint-up(xxl) {}
   }

   &__heading {
     position: relative;
     font-style: normal;
     color: var(--text-color);
     display: inline-block;
     font-weight: 600;
     font-size: 26px;
     line-height: 36px;
     margin-bottom: 14px;

     @include media-breakpoint-up(sm) {
       font-size: 28px;
       line-height: 40px;
       margin-bottom: 16px;
     }

     @include media-breakpoint-up(md) {
       font-size: 32px;
       line-height: 40px;
       margin-bottom: 18px;
     }

     @include media-breakpoint-up(lg) {
       font-size: 36px;
       line-height: 46px;
       margin-bottom: 18px;
     }

     @include media-breakpoint-up(xl) {
       font-size: 40px;
       line-height: 50px;
       margin-bottom: 20px;
     }

     @include media-breakpoint-up(xxl) {
       font-size: 44px;
       line-height: 54px;
       margin-bottom: 20px;
     }
   }

   &__description {
     font-family: var(--font-family-sec);
     color: var(--text-color);
     margin-right: auto;
     margin-left: auto;
     border-left: 8px solid #A733FF;
     font-style: normal;
     font-weight: 500;
     padding: 0 5px;
     font-size: 16px;
     line-height: 23px;
     letter-spacing: 0.02em;
     width: 100%;

     @include media-breakpoint-up(sm) {
       padding: 0 10px;
       font-size: 17px;
       line-height: 23px;
       letter-spacing: 0.02em;
       width: 95%;
     }

     @include media-breakpoint-up(md) {
       padding: 0 12px;
       font-size: 18px;
       line-height: 24px;
       letter-spacing: 0.02em;
       width: 90%;
     }

     @include media-breakpoint-up(lg) {
       padding: 0 15px;
       font-size: 19px;
       line-height: 24px;
       letter-spacing: 0.02em;
       width: 90%;
     }

     @include media-breakpoint-up(xl) {
       padding: 0 20px;
       font-size: 20px;
       line-height: 24px;
       letter-spacing: 0.02em;
       width: 85%;
     }

     @include media-breakpoint-up(xxl) {
       padding: 0 22px;
       font-size: 20px;
       line-height: 24px;
       letter-spacing: 0.02em;
       width: 75%;
     }
   }
 }


 /* TIMELINE
–––––––––––––––––––––––––––––––––––––––––––––––––– */
 /* TIMELINE ARROWS
–––––––––––––––––––––––––––––––––––––––––––––––––– */
 .timeline-wrapper {
   white-space: nowrap;
   overflow-x: hidden;

   ol {
     width: 100%;
     padding: 250px 0;
     transition: all 1s;

     li {
       position: relative;
       display: inline-block;
       list-style-type: none;
       width: 160px;
       height: 3px;
       background: linear-gradient(#C301AF 100%, #9100FF 100%);

       &:not(:first-child) {
         margin-left: 14px;
       }

       &:not(:last-child) {
         &::after {
           content: '';
           position: absolute;
           top: 50%;
           left: calc(100% + 1px);
           bottom: 0;
           width: 12px;
           height: 12px;
           transform: translateY(-50%);
           border-radius: 50%;
           background: #D9D9D9;
           border: 1px solid #FE01E4;
         }
       }

       .timeline {
         width: 100%;
         position: relative;

         &__body {
           background: var(--dark-2);
           box-shadow: 0px 4px 75px rgba(0, 0, 0, 0.5);
           border: 1px solid #9100FF;
           border-radius: 15px;
           padding: 40px 12px;
         }

         &__body {
           font-weight: 500;
           font-size: 16px;
           line-height: 36px;
           text-align: center;
           color: var(--text-color);
         }
       }

       .outer-wrapper {
         position: absolute;
         left: calc(50% + 7px);
         width: 160px;
         font-size: 1rem;
         white-space: normal;
         color: black;

         &::before {
           content: '';
           position: absolute;
           top: 100%;
           left: 0;
           width: 2px;
           height: 30px;
           background: #FE01E4;
           transform: translate(-50%, -50%);
         }
       }

       &:nth-child(even) {
         .outer-wrapper {
           top: -50px;
           transform: translateY(-100%);

           &::before {
             position: absolute;
             top: auto;
             bottom: -52px;
             left: 50%;
           }
         }
       }

       &:nth-child(odd) {
         .outer-wrapper {
           top: calc(100% + 60px);

           &::before {
             position: absolute;
             top: -26px;
             left: 50%;
           }
         }
       }
     }
   }
 }


 /* GENERAL MEDIA QUERIES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
 @media screen and (max-width: 768px) {
   .timeline-wrapper {
     ol {
       width: auto;
       padding: 0;
       transform: none !important;

       li {
         width: auto;
         display: block;
         height: auto;
         background: transparent;

         &:first-child {
           margin-top: 25px;
         }

         &:not(:first-child) {
           margin-left: auto;
         }

         .outer-wrapper {
           width: 90%;
           height: auto !important;
           margin: 0 auto 25px;
           position: static;
         }

         &:nth-child(odd) {
           .outer-wrapper {
             top: calc(100% + 60px);

             &::before {
               top: 100%;
               transform: translateX(-50%);
               height: 25px;
             }
           }
         }

         &:nth-child(even) {
           .outer-wrapper {
             transform: translateY(-0%);

             &::before {
               top: 100%;
               transform: translateX(-50%);
             }
           }
         }
       }
     }
   }

 }