/*
* = Buttons
*/

.btn {
  font-family: var(--font-family-sec);
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  border-radius: 15px;
  padding: 6px 35px;

  // Extra Small devices (landscape phones, 376px and up)
  @include media-breakpoint-up(xs) {
    font-size: 14px;
    line-height: 19px;
    padding: 8px 35px;
  }

  // Small devices (landscape phones, 576px and up)
  @include media-breakpoint-up(sm) {
    font-size: 15px;
    line-height: 19px;
    padding: 9px 35px;

  }

  // Medium devices (tablets, 768px and up)
  @include media-breakpoint-up(md) {
    font-size: 16px;
    line-height: 19px;
    padding: 10px 40px;
  }

  // Large devices (desktops, 992px and up)
  @include media-breakpoint-up(lg) {
    font-size: 16px;
    line-height: 19px;
    padding: 14px 45px;
  }

  // X-Large devices (large desktops, 1200px and up)
  @include media-breakpoint-up(xl) {
    font-size: 16px;
    line-height: 19px;
    padding: 14px 50px;
  }

  &-sm {
    font-size: 16px;
    line-height: 19px;
    border-radius: 15px;
    padding: 8px 25px;

    // Extra Small devices (landscape phones, 376px and up)
    @include media-breakpoint-up(xs) {
      font-size: 16px;
      line-height: 19px;
      padding: 8px 25px;
    }

    // Small devices (landscape phones, 576px and up)
    @include media-breakpoint-up(sm) {
      font-size: 16px;
      line-height: 19px;
      padding: 10px 30px;

    }

    // Medium devices (tablets, 768px and up)
    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 19px;
      padding: 12px 35px;
    }

    // Large devices (desktops, 992px and up)
    @include media-breakpoint-up(lg) {
      font-size: 16px;
      line-height: 19px;
      padding: 12px 35px;
    }

    // X-Large devices (large desktops, 1200px and up)
    @include media-breakpoint-up(xl) {
      font-size: 16px;
      line-height: 19px;
      padding: 12px 40px;
    }
  }

  &-lg {

    // Extra Small devices (landscape phones, 376px and up)
    @include media-breakpoint-up(xs) {}

    // Small devices (landscape phones, 576px and up)
    @include media-breakpoint-up(sm) {}

    // Medium devices (tablets, 768px and up)
    @include media-breakpoint-up(md) {}

    // Large devices (desktops, 992px and up)
    @include media-breakpoint-up(lg) {}

    // X-Large devices (large desktops, 1200px and up)
    @include media-breakpoint-up(xl) {}
  }

  &-main {
    color: var(--white);
    background-image: linear-gradient(#0E0019, #0E0019), radial-gradient(circle at left top, #9100FF, #C301AF);
    border: 3px solid transparent;
    background-origin: border-box;
    background-clip: padding-box, border-box;

    &:hover,
    &:focus,
    &:active {
      color: var(--white);
      box-shadow: 0px 0px 40px 0px rgb(182 49 167);
    }
  }

  &-gradient {
    color: var(--white);
    background: #0E0019;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #9100FF, #C301AF);
    background-image: linear-gradient(to right, #9100FF 0%, #C301AF 100%);
    border-radius: 15px;
    padding: 6px 35px;

    // Extra Small devices (landscape phones, 376px and up)
    @include media-breakpoint-up(xs) {
      font-size: 16px;
      line-height: 19px;
      padding: 8px 35px;
    }

    // Small devices (landscape phones, 576px and up)
    @include media-breakpoint-up(sm) {
      font-size: 16px;
      line-height: 19px;
      padding: 9px 35px;

    }

    // Medium devices (tablets, 768px and up)
    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 19px;
      padding: 10px 40px;
    }

    // Large devices (desktops, 992px and up)
    @include media-breakpoint-up(lg) {
      font-size: 16px;
      line-height: 19px;
      padding: 14px 45px;
    }

    // X-Large devices (large desktops, 1200px and up)
    @include media-breakpoint-up(xl) {
      font-size: 16px;
      line-height: 19px;
      padding: 14px 50px;
    }

    transition: all 0.5s ease-in-out;

    &:hover,
    &:focus,
    &:active {
      transition: all 0.5s ease-in-out;
      color: #fff !important;
      box-shadow: 2px 2px 50px 1px #9100FF;
      background: #0E0019;
      background-image: linear-gradient(#0E0019, #0E0019);
    }
  }

  &:hover,
  &:active,
  &:focus {}
}

// Fix for darken background color
@each $color,
$value in $theme-colors {
  .btn-#{$color} {
    &:hover {
      background-color: darken($value, 5%);
    }
  }
}