 // Configuration
 @import "~bootstrap/scss/functions";
 @import "~bootstrap/scss/variables";
 @import "~bootstrap/scss/maps";
 @import "~bootstrap/scss/mixins";

 // @import url('https://fonts.cdnfonts.com/css/clash-display');
 //  @import url('https://fonts.cdnfonts.com/css/neue-haas-grotesk-display-pro');
 $font-family-primary: 'Clash Display', sans-serif;
 $font-family-sec: 'Neue Haas Grotesk Display Pro', sans-serif;

 $container-margins: 30px;
 $container-margins-xs: 30px;
 $container-margins-sm: 40px;
 $container-margins-md: 80px;
 $container-margins-lg: 100px;
 $container-margins-xl: 120px;
 $container-margins-xxl: 120px;

 // Variables
 //
 // Variables should follow the `$component-state-property-size` formula for
 // consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

 // Color system 
 //
 /// Slightly lighten a color 
 @function tint($color, $percentage) {
   @return mix(white, $color, $percentage);
 }

 /// Slightly darken a color 
 @function shade($color, $percentage) {
   @return mix(black, $color, $percentage);
 }

 $primary: #8200E5;
 $primary-100: shade($primary, 9%);
 $primary-200: shade($primary, 18%);
 $primary-300: shade($primary, 27%);
 $primary-400: shade($primary, 36%);
 $primary-500: shade($primary, 45%);
 $primary-600: shade($primary, 54%);
 $primary-700: shade($primary, 63%);
 $primary-800: shade($primary, 72%);
 $primary-900: shade($primary, 81%);
 $primary-900: shade($primary, 80%);


 $primary-2: #8200E5;
 $primary-2-100: tint($primary-2, 9%);
 $primary-2-200: tint($primary-2, 18%);
 $primary-2-300: tint($primary-2, 27%);
 $primary-2-400: tint($primary-2, 36%);
 $primary-2-500: tint($primary-2, 45%);
 $primary-2-600: tint($primary-2, 54%);
 $primary-2-700: tint($primary-2, 63%);
 $primary-2-800: tint($primary-2, 72%);
 $primary-2-900: tint($primary-2, 81%);
 $primary-2-900: tint($primary-2, 80%);

 // Color scheme
 $primary: #FE01E4;
 $primary-active: #9100FF;
 $primary-hover: #FE01E4;
 $secondary: #EDC8A6;
 //  $light : #FCF9F4;
 $light : #F2F2F2;
 $dark : #1A0F24;
 $dark-2 : #1A0F24;
 $body : #281637;
 $body-gradient : radial-gradient(18% 28% at 3% 61%, #c301b041 29%, #073AFF00 100%),
 radial-gradient(18% 28% at 91% 33%, #9000ff49 30%, #073AFF00 100%),
 linear-gradient(152deg, #281637 1%, #281637 100%);

 $body-gradient-light: radial-gradient(18% 28% at 3% 61%, #ff9bf579 29%, transparent 100%),
   radial-gradient(18% 28% at 91% 33%, #d9a7ff5d 30%, transparent 100%),
   linear-gradient(152deg, transparent 1%, transparent 100%);

 $bg-gradient-1: radial-gradient(28% 28% at 5% 85%, #c301b054 20%, transparent 100%),
   radial-gradient(30% 30% at 85% 5%, #9000ff4b 20%, #073AFF00 100%),
   linear-gradient(152deg, #281637 1%, #281637 100%);
 $bg-gradient-1-light: radial-gradient(28% 28% at 5% 85%, #c301b054 20%, #073AFF00 100%),
   radial-gradient(30% 30% at 85% 5%, #9000ff4b 20%, transparent 100%),
   linear-gradient(152deg, transparent 1%, transparent 100%);
 $bg-gradient-2: linear-gradient(154deg, #980189 3.29%, #7400CC 95.81%);
 $bg-gradient-2-light: linear-gradient(154deg, #98018981 3.29%, #ffffff 95.81%);

 $white : #fff;
 $white-2 : #E4E4E4;
 $white-3 : #EAECF0;
 $text-color : #fff;
 $pure-white : #fff;

 // Theme colors
 $theme-colors: (
   "primary": $primary,
   "secondary": $secondary,
   "light": $light,
   "white": $white
 );

 // Customize the light and dark text colors for use in our color contrast function.
 $color-contrast-dark : $dark;
 $color-contrast-light: $white;

 // Body 
 $body-bg : $body;
 //  $body-color : $primary; 
 // 
 // Typography 
 $font-family-sans-serif: $font-family-primary;

 // DARK & LIGHT Mode
 .dark-mode,
 :root {
   --bs-body-font-family: #{$font-family-primary};
   --font-family-sec: #{$font-family-sec};

   --primary: #{$primary};
   --secondary: #{$secondary};
   --light: #{$light};
   --dark: #{$dark};
   --dark-2: #{$dark-2};
   --dark-3: #{$dark-2};
   --white: #{$white};
   --white-2: #{$white-2};
   --white-3: #{$white-3};
   --text-color: #{$text-color};
   --pure-white: #{$pure-white};

   // GRADIENTS
   --bg-gradient-1: #{$bg-gradient-1};
   --bg-gradient-2: #{$bg-gradient-2};

   --bs-body-color: #F2F2F2;
   --bs-body-bg: #{$body-bg};
   --bs-body-bg-gradient: #{$body-gradient};
 }

 //  @include var(background-color, primary, black);

 .light-mode {
   --secondary: #{$secondary};
   --light: #{(lighten($dark, 5%))};
   --dark: #{$white};
   --dark-2: #{lighten($dark-2, 60%)};
   --dark-3: #fff;
   --white: #{$white};
   --white-2: #{darken($text-color, 65%)};
   --white-3: #{darken($text-color, 70%)};
   --text-color: #{darken($text-color, 75%)};

   // GRADIENTS
   --bg-gradient-1: #{$bg-gradient-1-light};
   --bg-gradient-2: #{$bg-gradient-2-light};

   --bs-body-color: $body-bg;
   //  --bs-body-bg: #F2F2F2;
   --bs-body-bg: #{lighten($light, 5%)};
   --bs-body-bg-gradient: #{$body-gradient-light};
 }

 @media (prefers-color-scheme: light) {}

 /* Dark mode */
 @media (prefers-color-scheme: dark) {}

 @media (prefers-reduced-motion: no-preference) {
   //  .App-logo {
   //    animation: App-logo-spin infinite 20s linear;
   //  }
 }

 // Set a specific jump point for requesting color jumps
 $theme-color-interval: 8%;

 // Paragraphs 
 $paragraph-margin-bottom: 1rem;

 // Grid breakpoints  
 $grid-breakpoints: (
   xxs: 0,
   xs: 376px,
   sm: 576px,
   md: 768px,
   lg: 992px,
   xl: 1200px,
   xxl: 1450px
 );

 // Grid containers 
 $container-max-widths: (
   xxs: 0,
   xs: 386px,
   sm: 560px,
   md: 740px,
   lg: 980px,
   xl: 1200px,
   xxl: 1450px
 );

 // 
 // Margin and padding 
 // 
 // $spacer: 1rem;
 // $spacers: (
 //   0: 0,
 //   1: $spacer * .25,
 //   2: $spacer * .5,
 //   3: $spacer,
 //   4: $spacer * 1.5,
 //   5: 150px,
 // );

 // Grid columns 
 $grid-columns : 12;
 $grid-gutter-width: 1.5rem;
 $grid-row-columns : 6;
 $grid-columns : 24;
 $grid-gutter-width: 24px;
 $grid-row-columns : 6;

 // Container padding  
 $paragraph-font-size : 1rem;
 $paragraph-font-weight: 300;
 $paragraph-line-height: 1.6;