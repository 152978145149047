 @import "./../../Styles/variables";
 $product-border-radius: 25px;

 .product {
   $root: &;

   &__avatar {
     filter: drop-shadow(0px 4px 70px #000000);
     filter: drop-shadow(2px 4px 10px black);
   }

   &__body {

     #{$root}:hover &,
     #{$root}:focus &,
     #{$root}:active & {}
   }

   &__heading {
     font-weight: 600;
     font-size: 44px;
     line-height: 54px;
     color: var(--text-color);
   }

   &__description {
     font-family: var(--font-family-sec);
     font-weight: 500;
     font-size: 16px;
     line-height: 32px;
     color: var(--text-color);
   }
 }

 .rounded {}

 .row {}

 .w-100 {}

 .products-splide {

   .splide__pagination {
     bottom: -30px;

     li {

       .splide__pagination__page {
         width: 12px;
         height: 12px;
         border-radius: 50%;
         background: #000000;

         &.is-active {
           background: linear-gradient(180deg, #C301AF 0%, #9100FF 100%);
         }
       }
     }
   }

   .splide__track--nav>.splide__list>.splide__slide {
     border-width: 0px !important;
     opacity: 1;

     &.is-active {}
   }
 }

 .splide {

   &__arrows {
     display: flex;
     gap: 15px;
     align-items: center;
     justify-content: flex-start;
     justify-content: center;
   }

   &__arrow {
     margin: 5px;
     padding: 5px;
     transform: translateY(0%) !important;
     position: static !important;

     &--next {
       margin-left: 35px;
     }

     &--prev {}
   }
 }

 // slider-price CARD 
 .sliderPrice {
   background: #480080;
   border-radius: 25px;
   padding: 30px 30px;

   &__eth {
     font-weight: 600;
     font-size: 50px;
     line-height: 62px;
     color: var(--white);
     margin-bottom: 5px;
   }

   &__price {
     font-weight: 500;
     font-size: 26px;
     line-height: 32px;
     color: var(--white);
     margin-bottom: 50px;
   }

   &__heading {
     font-family: var(--font-family-sec);
     font-style: normal;
     font-weight: 500;
     font-size: 16px;
     line-height: 19px;
     color: var(--white);
   }

   &__time {
     font-style: normal;
     font-weight: 600;
     font-size: 26px;
     line-height: 32px;
     color: var(--white);
   }

   &__wrapper {}
 }